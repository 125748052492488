import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
	selector: 'app-bug-report',
	templateUrl: './bug-report.component.html',
	styleUrls: ['./bug-report.component.scss']
})
export class BugReportComponent implements OnInit {

	description: string;
	reproduce: string;
	loading: boolean;

	constructor(private userService: UserService, private snackBar: MatSnackBar) { }

	ngOnInit() {
		this.loading = false;
	}

	/**
	 * The action when the user submits the bug report form. Calls the UserService to
	 * save the bug information in Firebase
	 * @see UserService
	 */
	submitBugReport() {
		this.loading = true;
		this.userService.submitBugReport(this.description, this.reproduce).then(() => {
			this.description = '';
			this.reproduce = '';
			this.popToast('Thank you for submitting a bug report!', '', 'toast-success')
			this.loading = false;
		}).catch(err => {
			console.error(err);
			this.popToast('An error occurred. Please refresh and try again', '', 'toast-error');
		});
	}

	/**
     * Pops up a toast message using Angular Material's 'SnackBar' Component.
     * @param message The message to display in the toast (snackBar)
     * @param action Optional action button text
	 * @param style CSS style to use 'toast-error' or 'toast-success'
     */
	 popToast(message: string, action: string, style: string) {
        this.snackBar.open(message, action, {
            duration: 5000,
            panelClass: style
        });
    }

}
