import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import * as _ from 'underscore';

@Component({
  selector: 'app-video-tutorial-list',
  templateUrl: './video-tutorial-list.component.html',
  styleUrls: ['./video-tutorial-list.component.scss']
})
export class VideoTutorialListComponent implements OnInit {

  @Input() tutorials: any[];
  @Input() admin: boolean;

  _grade: number;

	@Input() set grade(value: number) {
		this._grade = value;
		this.sortResources();
	}
	get grade(): number {
		return this._grade;
	}

  @Output() edit = new EventEmitter();

  modalVideoUrl: SafeResourceUrl;

  topics = ['DMTI Overviews', 'Fluency & Flexibility', 'Measurement', 'Data', 'Geometry', 'Number Primary', 'Number Intermediate', 'Number Middle School', 'Algebraic Reasoning'];

	sortedResources = {
		'DMTI Overviews': [],
		'Fluency & Flexibility': [],
		'Measurement': [],
		'Data': [],
		'Geometry': [],
		'Number Primary': [],
		'Number Intermediate': [],
		'Number Middle School': [],
		'Algebraic Reasoning': []
	}

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.sortResources();
  }


  /**
	 * Creates a vimeo embed code from a Vimeo URL string
	 * @param {string} video The original Vimeo URL
	 */
	getVimeoEmbed(video: string) {
    let videoID = new URL(video).pathname;
    this.modalVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://player.vimeo.com/video/' + videoID.slice(1) + '?byline=0&portrait=0');
}

  /**
	 * Parses a filename out of a full Firebase storage path
	 * @param path The Firebase Storage path
	 * @returns {string} The filename
	 */
	getFilename(path: string) {
		return path.substring(29);
	}


  /**
	 * Tells the parent component that the resource needs to be edited.
	 * Parent component should navigate to /edit-resource/{id}
	 * @param resource The resource to be edited
	 */
	editResource(resource: any) {
		this.edit.emit(resource);
	}

	/**
	 * Sorts resources into categories by topic
	 */
	sortResources() {
    this.sortedResources = {
			'DMTI Overviews': [],
			'Fluency & Flexibility': [],
			'Measurement': [],
			'Data': [],
			'Geometry': [],
			'Number Primary': [],
			'Number Intermediate': [],
			'Number Middle School': [],
			'Algebraic Reasoning': []
		}
		for (let topic of this.topics) {
			let topicArray = _.filter(this.tutorials, (tutorial) => {
				return _.contains(tutorial.topic, topic)
			});
			this.sortedResources[topic] = topicArray;
		}
	}

}
