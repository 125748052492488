import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DiagnosticService } from '../../services/diagnostic.service';
import { UserService } from '../../services/user.service';
import { DocumentReference } from '@angular/fire/firestore';
import * as _ from 'underscore';

@Component({
    selector: 'app-diagnostic',
    templateUrl: './diagnostic.component.html',
    styleUrls: ['./diagnostic.component.scss']
})

export class DiagnosticComponent implements OnInit {

    DEBUG = false;

    answers: any[] = [];
    subcategories: string[];

    quizType: string;
    category: string;
    student: DocumentReference;
    teacher: DocumentReference;
    studentCode: string;
    finalTime: string;
    childFirstName = '';
    childLastName = '';

    nextSlideToLoad = 0;
    numberCorrect = 0;
    percentCorrect: number;
    numberOfQuestions: number;

    quizStarted = false;
    quizDone = false;
    buttonDisabled = false;

    currentAnswer: any;
    currentSlideObject: any;
    codeData: any;

    overallTimer = {
        seconds: 0,
        minutes: 0,
        hours: 0
    };

    questionTimer = {
        seconds: 0,
        minutes: 0,
        hours: 0
    };

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        public diagService: DiagnosticService,
        public userService: UserService
    ) { }


    ngOnInit() {
        if (this.DEBUG) { console.log('ngOnInit called'); }

        this.quizStarted = false;
        this.resetQuizValues();
        this.quizType = this.activatedRoute.snapshot.paramMap.get('type');
        this.studentCode = this.activatedRoute.snapshot.paramMap.get('code');

        this.userService.ifCodeExists(this.studentCode).then(res => {
            this.codeData = res.data();
            this.diagService.setStudentGrade(this.codeData.grade);
            this.student = this.codeData.student;
            this.teacher = this.codeData.teacher;
            this.loadSlide();
        }).catch(err => {
            console.error(err);
        });
    }



    /**
     * Initialize timer and load first slide
     */
    beginQuiz(): void {
        this.quizStarted = true;
        this.numberOfQuestions = this.diagService.getNumberOfQuestions();

        setInterval(() => {
            this.timer();
            this.individualQuestionTimer();
        }, 1000);

        this.nextSlideToLoad++;
    }



    /**
     * Save the current answer and Grab the JSON data for the currently displayed slide.
     */
    loadSlide(skip?: boolean): void {
        if (this.DEBUG) { console.log('nextSlideToLoad: ', this.nextSlideToLoad); }

        this.buttonDisabled = true;
    
        if (this.nextSlideToLoad === 0) {
            this.diagService.getSlide(0, this.quizType).then(res => {
                this.currentSlideObject = res;
                this.subcategories = this.diagService.getSubcategories();
                this.category = this.diagService.getCategory();
            });
        } else if (this.nextSlideToLoad <= this.numberOfQuestions) {

            if (this.currentSlideObject) {
                let answer: any;
                if (skip) {
                    answer = {
                        questionId: this.currentSlideObject.id,
                        format: 'skipped',
                        answer: false,
                        time: this.timerToString(this.questionTimer)
                    };
                } else {
                    answer = {
                        questionId: this.currentSlideObject.id,
                        format: this.currentSlideObject.answerFormat,
                        answer: this.currentAnswer,
                        time: this.timerToString(this.questionTimer)
                    };
                }
                this.diagService.saveAnswer(answer);
            }

            if (this.nextSlideToLoad === this.numberOfQuestions) {
                this.finishQuiz();
            } else {
                this.diagService.getSlide(this.nextSlideToLoad).then(res => {
                    this.currentSlideObject = res;
                });
                this.questionTimer.hours = 0;
                this.questionTimer.minutes = 0;
                this.questionTimer.seconds = 0;
                this.nextSlideToLoad++;
                this.currentAnswer = '';
            }
        }
        if (this.DEBUG) {
            console.log('this.category: ', this.category);
            console.log('this.subcategories: ', this.subcategories);
        }
    }



    /**
     * Load the last slide, create a results object and continue to the saveResults function.
     * @see saveResults
     */
    finishQuiz(): void {
        this.finalTime = this.timerToString(this.overallTimer);
        this.numberCorrect = this.diagService.getNumberCorrect();
        this.percentCorrect = (this.numberCorrect / this.numberOfQuestions) * 100;
        this.percentCorrect = Number.parseFloat(this.percentCorrect.toFixed(0));
        this.answers = this.diagService.getAnswers();

        const resultsObject = {
            subcategories: this.subcategories,
            answers: this.answers,
            percentCorrect: this.percentCorrect,
            finalTime: this.finalTime,
            numberCorrect: this.numberCorrect,
            numberOfQuestions: this.numberOfQuestions,
            category: this.category,
            grade: this.codeData.grade
        };

        if (this.DEBUG) {
            console.log('resultsObject right before sending it to UserService: ', resultsObject);
        }

        this.saveResults(resultsObject);
    }



    /**
     * Call our user service to save the student's results to Firebase.
     * Firebase Functions then saves a reference to it in the student's Firebase document.
     * @param resultsObject The full results object to be saved in Firebase as a document
     */
    saveResults(resultsObject: any): void {
        this.userService.saveResults(resultsObject, this.student, this.teacher).then(async res => {
            this.diagService.clearQuiz();
            
            this.userService.removeCodeAfterQuiz(this.studentCode).then(() => {
                this.quizDone = true;
                this.resetQuizValues();
                this.router.navigate(['/finished']);
            });
        }).catch(err => {
            console.error(err);
        });

        if (this.DEBUG) { console.table(this.answers); }
    }



    /**
     * Reset some class variables to their default values.
     */
    resetQuizValues(): void {
        this.subcategories = [];
        this.answers = [];
        this.percentCorrect = 0;
        this.numberCorrect = 0;
        this.numberOfQuestions = 0;
        this.category = '';
        this.nextSlideToLoad = 0;
        this.currentAnswer = {};
        this.currentSlideObject = {};
        this.quizStarted = false;
    }


    /**
     * Event handler for the text field answer. Sent from quizzes that have the 'textInput' answerFormat.
     * @param text The user input from the answer box
     */
    onTextInputChanged(text: string): void {
        this.currentAnswer = text;
        if (this.currentAnswer !== '') {
            this.buttonDisabled = false;
        } else {
            this.buttonDisabled = true;
        }

        if (this.DEBUG) { console.log('current answer (text changed): ', this.currentAnswer); }
    }



    /**
     * Event handler for the radio button answer option. Sent from quizzes that have the 'multipleChoice' answerFormat.
     * @param radioObject Object containing the text value and the boolean flag that indicates if it's correct or not
     */
    onRadioChanged(radioObject: any): void {
        this.currentAnswer = radioObject.answerIsCorrect;
        this.buttonDisabled = false;

        if (this.DEBUG) { console.log('current answer (radio changed): ', this.currentAnswer); }
    }



    /**
     * Event handler for the checkbox answer options. Sent from the quizzes that have the 'checkbox' answerFormat.
     * @param checkboxObject Object containing all of the checkbox options and wether or not they are correct
     */
    onCheckboxChanged(checkboxObject: any): void {
        this.currentAnswer = checkboxObject;
        this.buttonDisabled = false;

        if (this.DEBUG) { console.log('current answer (checkbox changed): ', this.currentAnswer); }
    }



    /**
     * Event handler for the multiple text input answer options. Sent from the quizzes that have the 'multipleText' answerFormat.
     * @param multipleTextArray An array containing the user-inputted answer strings
     */
    onMultipleTextChanged(multipleTextArray: string[]): void {
        this.currentAnswer = multipleTextArray;
        this.buttonDisabled = false;

        if (this.DEBUG) { console.log('current answer (multipleText changed): ', this.currentAnswer); }
    }



    /**
     * Click handler for the next slide button.
     */
    onNextSlideEvent(): void {
        if (!this.buttonDisabled) {
            this.loadSlide();
        }
    }



    /**
     * Keeps track of the hours, minutes, and seconds that have elapsed since the quiz was started
     */
    timer(): void {
       if (this.overallTimer.seconds === 60) {
           if (this.overallTimer.minutes === 60) {
               this.overallTimer.hours++;
               this.overallTimer.minutes = 0;
           } else {
               this.overallTimer.minutes++;
               this.overallTimer.seconds = 0;
           }
       } else {
           this.overallTimer.seconds++;
       }
    }



    /**
     * Keeps track of the hours, minutes, and seconds that have elapsed since a question was displayed.
     */
    individualQuestionTimer(): void {
        if (this.questionTimer.seconds === 60) {
            if (this.questionTimer.minutes === 60) {
                this.questionTimer.hours++;
                this.questionTimer.minutes = 0;
            } else {
                this.questionTimer.minutes++;
                this.questionTimer.seconds = 0;
            }
        } else {
            this.questionTimer.seconds++;
        }
    }



    /**
     * Formats the timer object into a string ready for display
     * @param timer a timer object containing hours, minutes, and seconds
     * @returns A formatted string
     */
    timerToString(timer: any): string {
        return timer.hours + 'h ' + timer.minutes + 'm ' + timer.seconds + 's';
    }
}
