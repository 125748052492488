import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../services/user.service';
import { DiagnosticService } from '../../services/diagnostic.service';
import { isNullOrUndefined } from 'util';
import * as Highcharts from 'highcharts';
import exporting from 'highcharts/modules/exporting';
import * as Lodash from 'lodash';
import * as _ from 'underscore';
import * as moment from 'moment';
import { firestore } from 'firebase';
import { HighchartsChartComponent, HighchartsChartModule } from 'highcharts-angular';
exporting(Highcharts);


type Result = {
    data: {
        answers: any[];
        category: string;
        date: firestore.Timestamp;
        finalTime: string;
        numberCorrect: number;
        numberOfQuestions: number;
        percentCorrect: number;
        student: firestore.DocumentReference;
        subcategories: string[];
        subcategoryScores: any;
        teacher: firestore.DocumentReference;
        id: string;
    }
    id: string;
}

@Component({
    selector: 'app-reports-detail',
    templateUrl: './reports-detail.component.html',
    styleUrls: ['./reports-detail.component.scss']
})



export class ReportsDetailComponent implements OnInit {

    firebaseID: string;
    Highcharts = Highcharts;
    loading = true;
    chartData: any[] = [];
    results: Result[];
    categories: string[];
    categoryData: any;
    student: any;

    subcategories = {
        'Algebraic Reasoning': ["Context", "Symbolic", "Relational"],
        'Fraction Concepts': ["Greatest Amount", "Bar Model", "Number Line", "Locate 1 on NL"],
        'Geometric Measurement': ["Compose", "Iterate 2D", "Iterate 3D"],
        'Multiplication & Division Fluency': ["Multiplication Bar Model", "Division Bar Model", "Multiplication Facts", "Division Facts"],
        'Place Value Concepts': ["Concepts", "Add/Sub", "Division Context"],
        'Ratio Proportion Concepts': ["Chips", "Bar Model", "Number Line"],
        'Decimal Concepts': ["Visual", "Number Line", "Greatest Amount", "Place Value Units"]
    }

    preChartData = {
        'Algebraic Reasoning': [],
        'Fraction Concepts': [],
        'Geometric Measurement': [],
        'Multiplication & Division Fluency': [],
        'Place Value Concepts': [],
        'Ratio Proportion Concepts': [],
        'Decimal Concepts': []
    }

    constructor(private activatedRoute: ActivatedRoute, private userService: UserService, private diagService: DiagnosticService) { }

    ngOnInit() {
        this.firebaseID = this.activatedRoute.snapshot.paramMap.get('id');
        this.categories = _.keys(this.preChartData);

        // GET STUDENT DATA
        this.userService.getStudentByID(this.firebaseID).then(studentRes => {
            this.student = studentRes.data();

            if (this.student.grade > 3) {
                this.subcategories['Fraction Concepts'] = ["Greatest Amount", "Bar Model", "Number Line", "Locate 1 on NL", "Improper/Proper"]
                this.subcategories['Place Value Concepts'] = ["Concepts", "Add/Sub", "Multiplication", "Division", "Division Context"]
            } else {
                this.categories = _.without(this.categories, 'Decimal Concepts');
            }

            // GET RESULTS
            this.userService.getAllResultsForStudent(this.firebaseID).then(res => {
                this.results = res;
                if (res) {
                    this.sortData();
                    this.buildGraph();
                }
            }).catch(err => {
                this.loading = false;
                console.error(err);
            });
        }).catch(err => {
            this.loading = false;
            console.error(err);
        });
        this.setupPrintHandler();
    }


    /**
     * Transform result data for graph
     */
    sortData() {
        this.results = _.sortBy(this.results, (result)=> {
            return result.data.date.toDate();
        });

        for (let result of this.results) {
            let data = [];
            for (let subcat of this.subcategories[result.data.category]) {
                if (!result.data.subcategoryScores) {
                    result.data['subcategoryScores'] = this.userService.calculateSubcategoryScores(result.data.subcategories, result.data.answers);
                }
                let subcatScore = result.data.subcategoryScores[subcat];
                let percent: number;
                if (subcatScore['numCorrect'] === 0) {
                    percent = 0;
                } else {
                    percent = this.round((subcatScore['numCorrect'] / subcatScore['numQuestions']) * 100);
                }
                data.push(percent);
            }
            this.preChartData[result.data.category].push({
                date: moment(result.data.date.seconds * 1000).calendar(),
                data: data
            });
        }
        for (let category of _.keys(this.subcategories)) {
            if (this.preChartData[category].length === 0) {
                this.preChartData = _.omit(this.preChartData, category) as any;
                this.categories = _.without(this.categories, category);
            }
        }
    }


    /**
     * Create options object for highcharts graph data
     */
     buildGraph(): void {
        
        for (let i=0; i<this.categories.length; i++) {

            let category = this.categories[i];

            if (this.preChartData[category] && this.preChartData[category].length > 0) {
                const chartOptions = {
                    exporting: {
                        chartOptions: {
                            plotOptions: {
                                series: {
                                    dataLabels: {
                                        enabled: true
                                    }
                                }
                            }
                        },
                        fallbackToExportServer: false
                    },
                    chart: {
                        type: 'column'
                    },
                    title: {
                        text: ''
                    },
                    xAxis: {
                        categories: [
                        ],
                        crosshair: true
                    },
                    yAxis: {
                        min: 0,
                        max: 100,
                        title: {
                            text: 'Score (Percent)'
                        }
                    },
                    plotOptions: {
                        column: {
                            pointPadding: 0.2,
                            borderWidth: 0,
                            dataLabels: {
                                enabled: true,
                                style: {
                                    color: 'black'
                                }
                            }
                        }
                    },
                    legend: {
                        layout: 'vertical',
                        align: 'right',
                        verticalAlign: 'top',
                        x: -40,
                        y: 80,
                        floating: true,
                        borderWidth: 1,
                        backgroundColor:
                            Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
                        shadow: true
                    },
                    credits: {
                        enabled: false
                    },
                    series: []
                };
    
                
                for (let subcatData of this.preChartData[category]) {
                
                    chartOptions.xAxis.categories = this.subcategories[category];
                    chartOptions.series.push({
                        name: subcatData.date,
                        data: subcatData.data
                    });
                }
                
                this.chartData[category] = chartOptions;
            }

        }
        this.loading = false;
    }

    print() {
        window.print();
    }

    setupPrintHandler() {
        const mediaQueryList = window.matchMedia('print');
        mediaQueryList.addListener((mql) => {
            if (mql.matches) {
                let charts = Highcharts.charts;
                for (let chart of charts) {
                    chart.setSize(600, 300, false);
                }
            } else {
                let charts = Highcharts.charts;
                for (let chart of charts) {
                    chart.setSize(null, null, false);
                }
            }
        })
    }

    round(num: number) {
        var m = Number((Math.abs(num) * 100).toPrecision(15));
        return Math.round(m) / 100 * Math.sign(num);
    }
    
}
