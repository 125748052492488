import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { DiagnosticComponent } from './diagnostics/diagnostic/diagnostic.component';
import { AuthGuardService } from './services/auth-guard.service';
import { StudentDetailsPageComponent } from './student/student-details-page/student-details-page.component';
import { StudentsPageComponent } from './student/students-page/students-page.component';
import { ResultsDetailsPageComponent } from './results/results-details-page/results-details-page.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CreateStudentPageComponent } from './student/create-student-page/create-student-page.component';
import { DiagnosticListPageComponent } from './diagnostics/diagnostic-list-page/diagnostic-list-page.component';
import { EditStudentPageComponent } from './student/edit-student-page/edit-student-page.component';
import { DiagnosticLoginComponent } from './diagnostics/diagnostic-login/diagnostic-login.component';
import { ClassDetailComponent } from './class/class-detail/class-detail.component';
import { CreateClassPageComponent } from './class/create-class-page/create-class-page.component';
import { ReportsComponent } from './reports/reports.component';
import { ReportsDetailComponent } from './reports/reports-detail/reports-detail.component';
import { DiagnosticDetailComponent } from './diagnostics/diagnostic-detail/diagnostic-detail.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { DiagnosticFinishComponent } from './diagnostics/diagnostic-finish/diagnostic-finish.component';
import { TutorialPageComponent } from './tutorial-page/tutorial-page.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { AdminComponent } from './admin/admin.component';
import { BugReportPageComponent } from './bug/bug-report-page/bug-report-page.component';
import { BugViewComponent } from './bug/bug-view/bug-view.component';
import { EditTeacherComponent } from './teacher/edit-teacher/edit-teacher.component';
import { AddResourceComponent } from './resource/add-resource/add-resource.component';
import { EditResourceComponent } from './resource/edit-resource/edit-resource.component';
import { ResourcePageComponent } from './resource/resource-page/resource-page.component';
import { ResourceDetailComponent } from './resource/resource-detail/resource-detail.component';
import { SupplementalPageComponent } from './resource/supplemental-page/supplemental-page.component';
import { VideoTutorialPageComponent } from './resource/video-tutorial-page/video-tutorial-page.component';
import { SummerResourcePageComponent } from './resource/summer-resource-page/summer-resource-page.component';


const routes: Routes = [
	{ path: 'ima-dashboard', component: DashboardComponent, canActivate: [AuthGuardService] },
	{ path: 'class/:name', component: ClassDetailComponent, canActivate: [AuthGuardService] },
	{ path: 'add-class', component: CreateClassPageComponent, canActivate: [AuthGuardService] },
	{ path: 'results/:id', component: ResultsDetailsPageComponent },
	{ path: 'diagnostic-login', component: DiagnosticLoginComponent },
	{ path: 'diagnostic', redirectTo: 'quiz' },
	{ path: 'diagnostic/:type/:code', redirectTo: 'quiz/:type/:code' },
	{ path: 'add-resource', component: AddResourceComponent, canActivate: [AuthGuardService] },
	{ path: 'edit-resource/:id', component: EditResourceComponent, canActivate: [AuthGuardService] },
	{ path: 'resources', component: ResourcePageComponent, canActivate: [AuthGuardService] },
	{ path: 'summer-resources', component: SummerResourcePageComponent, canActivate: [AuthGuardService] },
	{ path: 'resource/:id', component: ResourceDetailComponent, canActivate: [AuthGuardService] },
	{ path: 'supplemental-materials', component: SupplementalPageComponent, canActivate: [AuthGuardService] },
	{ path: 'video-tutorials', component: VideoTutorialPageComponent, canActivate: [AuthGuardService] },
	{ path: 'quiz', component: DiagnosticLoginComponent },
	{ path: 'quiz/:type/:code', component: DiagnosticComponent },
	{ path: 'diagnostics', component: DiagnosticListPageComponent, canActivate: [AuthGuardService] },
	{ path: 'diagnostics/:diagnostic', component: DiagnosticDetailComponent, canActivate: [AuthGuardService] },
	{ path: 'student/:id', component: StudentDetailsPageComponent, canActivate: [AuthGuardService] },
	{ path: 'students', component: StudentsPageComponent, canActivate: [AuthGuardService] },
	{ path: 'add-student', component: CreateStudentPageComponent, canActivate: [AuthGuardService] },
	{ path: 'edit-student/:id', component: EditStudentPageComponent, canActivate: [AuthGuardService] },
	{ path: 'reports', component: ReportsComponent, canActivate: [AuthGuardService] },
	{ path: 'reports/:id', component: ReportsDetailComponent, canActivate: [AuthGuardService] },
	{ path: 'login', component: LoginComponent },
	{ path: 'report-bug', component: BugReportPageComponent, canActivate: [AuthGuardService] },
	{ path: 'signup', component: SignupComponent },
	{ path: 'finished', component: DiagnosticFinishComponent },
	{ path: 'tutorial', component: TutorialPageComponent },
	{ path: 'schedule', component: ScheduleComponent },
	{ path: 'reset-password', component: ResetPasswordComponent },
	{ path: 'admin', component: AdminComponent, canActivate: [AuthGuardService] },
	{ path: 'bugs', component: BugViewComponent, canActivate: [AuthGuardService] },
	{ path: 'edit-info', component: EditTeacherComponent, canActivate: [AuthGuardService] },
	{ path: '', redirectTo: '/resources', pathMatch: 'full' },
	{ path: '**', component: PageNotFoundComponent }
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
