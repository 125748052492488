import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { FilenameSortPipe } from '../../pipes/filename-sort.pipe';
import * as _ from 'underscore';

@Component({
  selector: 'app-video-tutorial-page',
  templateUrl: './video-tutorial-page.component.html',
  styleUrls: ['./video-tutorial-page.component.scss']
})
export class VideoTutorialPageComponent implements OnInit {

	tutorials: any[];
	filteredTutorials: any[];
	admin = false;
	grades = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
	selectedGrades = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
	filterText = '';
	topics = [
		'DMTI Overviews',
		'Fluency & Flexibility',
		'Measurement',
		'Data',
		'Geometry',
		'Number Primary',
		'Number Intermediate',
		'Number Middle School',
		'Algebraic Reasoning'
	];

	selectedTopics = [
		'DMTI Overviews',
		'Fluency & Flexibility',
		'Measurement',
		'Data',
		'Geometry',
		'Number Primary',
		'Number Intermediate',
		'Number Middle School',
		'Algebraic Reasoning'
    ];
    
    modalVideoUrl: SafeResourceUrl;

	constructor(private userService: UserService, private router: Router, private sanitizer: DomSanitizer) {
	}

	ngOnInit() {

		this.userService.getUserDetails().then(res => {
			const data = res.data();
			if (data.admin) {
				this.admin = data.admin;
			}
			this.userService.getResourcesByType('Tutorial').then(tutRes => {
				this.tutorials = _.sortBy(tutRes, 'title');
				this.filteredTutorials = _.sortBy(tutRes, 'title');
			}).catch(err => {
				console.error(err);
				alert('An error occurred. Please refresh and try again. If this continues, please submit a bug report.');
			});

		}).catch(err => {
			console.error(err);
			alert('An error occurred. Please refresh and try again. If this continues, please submit a bug report.');
		});
	}

	/**
	 * Navigate to the edit resource page
	 * @param resource The resource to be edited
	 */
	editResource(resource: any) {
		this.router.navigate(['/edit-resource', resource.id], {
			state: {
				resource,
				firebaseID: resource.id
			}
		});
	}

	/**
	 * Changes the displayed Supplemental Materials based on the selected grades and topics
	 */
	changeFilter() {
		this.filteredTutorials = _.filter(this.tutorials, (tut) => {
			return (_.some(this.selectedGrades, (grade) => {
				return _.contains(tut.grades, grade);
			})) && (_.some(this.selectedTopics, (topic) => {
				return _.contains(tut.topic, topic);
			}));
		});
	}


	/**
	 * Changes the displayed Supplemental Materials by searching for the filterText
	 * in each Supplemental Material's title and topic array
	 */
	nameSearch(): void {
		this.changeFilter();
		this.filteredTutorials = _.filter(this.filteredTutorials, (tut) => {
			let title = tut.title as string;
			let topics = tut.topic as string[];
			topics = _.map(topics, (topic) => {
				return topic.toLowerCase();
			}) as string[];
			title = title.toLowerCase();
			const lowercaseFilterText = this.filterText.toLowerCase();
			return title.includes(lowercaseFilterText) || topics.indexOf(lowercaseFilterText) > -1;
		});
    }


    /**
	 * Creates a vimeo embed code from a Vimeo URL string
	 * @param video The original Vimeo URL
	 */
	getVimeoEmbed(video: string): void {
        const videoID = new URL(video).pathname;
        this.modalVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://player.vimeo.com/video/' + videoID.slice(1) + '?byline=0&portrait=0');
    }


	/**
	 * Parses a filename out of a full Firebase storage path
	 * @param path The Firebase Storage path
	 * @returns The filename
	 */
	getFilename(path: string): string {
		return path.substring(29);
	}

}
