import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-class-page',
  templateUrl: './create-class-page.component.html',
  styleUrls: ['./create-class-page.component.scss']
})
export class CreateClassPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
