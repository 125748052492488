import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-class-list',
    templateUrl: './class-list.component.html',
    styleUrls: ['./class-list.component.scss']
})
export class ClassListComponent implements OnInit {

    @Input() classes: any[];
    @Output() selectClass = new EventEmitter<any>();

    constructor() { }

    ngOnInit() {
    }

    clickClass(classObj: any) {
        this.selectClass.emit(classObj);
    }

}
