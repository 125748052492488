import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit {

  displayedColumns: string[] = [
    'priority',
    'fall',
    'winter',
    'spring'
  ];

  grade3Array = [
    {
      priority: '1',
      fall: 'pv',
      winter: 'pv',
      spring: 'md'
    },
    {
      priority: '2',
      fall: 'gm',
      winter: 'md',
      spring: 'pv'
    },
    {
      priority: '3',
      fall: 'rp',
      winter: 'gm',
      spring: 'fc'
    },
    {
      priority: '4',
      fall: 'md shaded',
      winter: 'rp',
      spring: 'gm'
    },
    {
      priority: '5',
      fall: '',
      winter: 'ar shaded',
      spring: 'rp'
    },
    {
      priority: '6',
      fall: '',
      winter: '',
      spring: 'ar'
    }
  ]

  grade4Array = [
    {
      priority: '1',
      fall: 'md',
      winter: 'pv',
      spring: 'fc'
    },
    {
      priority: '2',
      fall: 'pv',
      winter: 'fc',
      spring: 'dc'
    },
    {
      priority: '3',
      fall: 'gm',
      winter: 'md',
      spring: 'md'
    },
    {
      priority: '4',
      fall: 'fc',
      winter: 'gm',
      spring: 'pv'
    },
    {
      priority: '5',
      fall: 'rp',
      winter: 'rp',
      spring: 'gm'
    },
    {
      priority: '6',
      fall: 'ar',
      winter: 'ar',
      spring: 'rp'
    },
    {
      priority: '7',
      fall: '',
      winter: '',
      spring: 'ar'
    }
  ]

  grade5Array = [
    {
      priority: '1',
      fall: 'fc',
      winter: 'fc',
      spring: 'fc'
    },
    {
      priority: '2',
      fall: 'dc',
      winter: 'dc',
      spring: 'dc'
    },
    {
      priority: '3',
      fall: 'md',
      winter: 'gm',
      spring: 'rp'
    },
    {
      priority: '4',
      fall: 'pv',
      winter: 'rp',
      spring: 'gm'
    },
    {
      priority: '5',
      fall: 'gm',
      winter: 'ar',
      spring: 'ar'
    },
    {
      priority: '6',
      fall: 'rp',
      winter: 'pv',
      spring: 'pv'
    },
    {
      priority: '7',
      fall: 'ar',
      winter: 'md',
      spring: 'md'
    }
  ]

  grade6Array = [
    {
      priority: '1',
      fall: 'rp',
      winter: 'rp',
      spring: 'rp'
    },
    {
      priority: '2',
      fall: 'ar',
      winter: 'ar',
      spring: 'ar'
    },
    {
      priority: '3',
      fall: 'fc',
      winter: 'fc',
      spring: 'fc'
    },
    {
      priority: '4',
      fall: 'dc',
      winter: 'dc',
      spring: 'dc'
    },
    {
      priority: '5',
      fall: 'gm',
      winter: 'gm',
      spring: 'gm'
    },
    {
      priority: '6',
      fall: 'pv',
      winter: 'pv',
      spring: 'pv'
    },
    {
      priority: '7',
      fall: 'md',
      winter: 'md',
      spring: 'md'
    }
  ]

  dataSourceGrade3: MatTableDataSource<any>;
  dataSourceGrade4: MatTableDataSource<any>;
  dataSourceGrade5: MatTableDataSource<any>;
  dataSourceGrade6: MatTableDataSource<any>;

  constructor() { }

  ngOnInit() {
    this.dataSourceGrade3 = new MatTableDataSource(this.grade3Array);
    this.dataSourceGrade4 = new MatTableDataSource(this.grade4Array);
    this.dataSourceGrade5 = new MatTableDataSource(this.grade5Array);
    this.dataSourceGrade6 = new MatTableDataSource(this.grade6Array);
  }


  getDisplayText(shortText: string) {
    switch(shortText) {
      case 'pv':
        return 'Place Value Concepts'
      case 'md':
        return 'Multiplication & Division Fluency'
      case 'md shaded':
        return 'Multiplication & Division Fluency'
      case 'gm':
        return 'Geometric Measurement'
      case 'pv':
        return 'Place Value Concepts'
      case 'rp':
        return 'Ratio & Proportion Concepts'
      case 'ar':
        return 'Algebraic Reasoning'
      case 'ar shaded':
        return 'Algebraic Reasoning'
      case 'fc':
        return 'Fraction Concepts'
      case 'dc':
        return 'Decimal Concepts'
      default:
        return ''
    }
  }
}
