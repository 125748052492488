import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-diagnostic-finish',
  templateUrl: './diagnostic-finish.component.html',
  styleUrls: ['./diagnostic-finish.component.scss']
})
export class DiagnosticFinishComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
