import { Component, OnInit, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { MatSidenav } from '@angular/material/sidenav'

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent {

    @ViewChild(MatSidenav, {static: false}) matSidenav: MatSidenav;

    isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
        .pipe(
            map(result => result.matches),
            shareReplay()
        );
    isTablet$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Tablet)
    .pipe(
        map(result => result.matches),
        shareReplay()
    );
    isTabPortrait$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.TabletPortrait)
    .pipe(
        map(result => result.matches),
        shareReplay()
    );
    isHandLandscape$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.HandsetLandscape)
    .pipe(
        map(result => result.matches),
        shareReplay()
    );
    isWebPortrait$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.WebPortrait)
    .pipe(
        map(result => result.matches),
        shareReplay()
    );
    isMedium$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Medium)
    .pipe(
        map(result => result.matches),
        shareReplay()
    );
    isSmall$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Small)
    .pipe(
        map(result => result.matches),
        shareReplay()
    );
    isTabLandscape$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.TabletLandscape)
    .pipe(
        map(result => result.matches),
        shareReplay()
    );
    constructor(public breakpointObserver: BreakpointObserver, public authService: AuthService) { }

    onQuizPage(): boolean {
        const url = window.location.href.toLowerCase();
        return url.includes('diagnostic-login') || url.includes('quiz');
    }

    logout(): void {
        this.authService.logout();
    }

    toggleDrawer() {
        this.matSidenav.toggle();
    }

}
