import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

@Component({
  selector: 'upload-task',
  templateUrl: './upload-task.component.html',
  styleUrls: ['./upload-task.component.scss']
})
export class UploadTaskComponent implements OnInit {

  @Input() file: File;

  @Output() uploadFinished = new EventEmitter();

  snapshot: Observable<any>;

  task: AngularFireUploadTask;

  url: string;
  downloadURL: string;

  percentage: Observable<number>;

  dateCreated = new Date();
  dateUpdated = new Date();

  constructor(private storage: AngularFireStorage) { }

  ngOnInit() {
    this.startUpload();
  }

  /**
   * Uploads file to firebase storage
   */
  startUpload() {
    // The storage path
    const path = 'resource-files/' + Date.now() + '/' + this.file.name;
    // Reference to storage bucket
    const ref = this.storage.ref(path);
    // The main task
    this.task = this.storage.upload(path, this.file);
    // Progress monitoring
    this.percentage = this.task.percentageChanges();
    this.snapshot   = this.task.snapshotChanges().pipe(
      tap(console.log),
      // The file's download URL
      finalize( async() =>  {
        this.downloadURL = await ref.getDownloadURL().toPromise();
        this.uploadFinished.emit({fileUrl: this.downloadURL, path: path});
        this.file = null;
      }),
    );
  }
  
  /**
   * Gets the snapshot of the current upload
   * @param snapshot 
   * @returns the state of the snapshot
   */
  isActive(snapshot) {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
  }
}