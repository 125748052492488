import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { ActivatedRoute } from '@angular/router';
import { DiagnosticService } from '../../services/diagnostic.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as _ from 'lodash';

@Component({
    selector: 'app-student-details-page',
    templateUrl: './student-details-page.component.html',
    styleUrls: ['./student-details-page.component.scss']
})

export class StudentDetailsPageComponent implements OnInit {

    error: string;
    student: any;
    firebaseID: any;
    diagnostics: any[];
    results: any[];
    className: string;
    selectedDiagnostic: any;
    readyToEnroll = false;
    generatingDiagnostic = false;
    studentWasDeleted = false;
    loading = false;

    constructor(
        private userService: UserService,
        private diagService: DiagnosticService,
        private activatedRoute: ActivatedRoute,
        private snackBar: MatSnackBar
    ) { }

    ngOnInit() {

        this.diagnostics = this.diagService.getQuizList();

        this.firebaseID = this.activatedRoute.snapshot.paramMap.get('id');

        this.userService.getStudentByDocument(this.firebaseID).then(studentResponse => {
            this.student = {
                firebaseID: this.firebaseID,
                data: studentResponse.data()
            };
            this.diagnostics = _.reject(this.diagnostics, (diagnostic: any) => {
                return _.indexOf(diagnostic.grades, this.student.data.grade) === -1;
            });
            this.userService.getAllResultsForStudent(this.student.firebaseID).then(resultRes => {
                resultRes = _.orderBy(resultRes, (result) => { return result.data.date.seconds}, ['desc']);
                this.results = resultRes;
            }).catch(err => {
                console.error(err);
            });
        });
    }



    /**
     * Use the UserService to remove the active diagnostic code from the activeDiagnostics collection
     * and remove it from the student's activeDiagnostic field.
     * @param code string - The active diagnostic code to be removed
     * @param student any - The student object used to update the front end
     */
    removeCode(student: any, codeObject: any) {
        this.userService.removeCode(student, codeObject).then(() => {
            student.data.activeCodes = _.reject(student.data.activeCodes, (codeObj: any) => {
                return _.isEqual(codeObj, codeObject);
            });
        }).catch(err => {
            console.error(err);
        });
    }



    /**
     * Click handler for selecting the diagnostic to enroll the student in.
     * @param diagnostic any - The selected diagnostic object
     */
    onSelectDiagnostic(diagnostic: any) {
        this.selectedDiagnostic = diagnostic;
        this.readyToEnroll = true;
    }



    /**
     * Loop through all students and add them to an active diagnostic window.
     */
    enrollDiagnostic() {
        this.generatingDiagnostic = true;

        if (this.selectedDiagnostic.grades.includes(this.student.data.grade)) {
            this.userService.addCode(this.student, this.selectedDiagnostic).then((studentRes) => {
                this.student.activeCodes = studentRes.activeCodes;
                this.generatingDiagnostic = false;
                this.popToast(this.student.data.firstName + ' was successfully enrolled in the Diagnostic.', '');
                this.clearSelections();
            }).catch(err => {
                this.generatingDiagnostic = false;
                console.error(err);
            });
        } else {
            this.popToast('ERROR: The student\'s grade is not supported by this diagnostic.', '');
        }
    }

    deleteStudent() {
        this.loading = true;
        this.userService.deleteStudent(this.student.firebaseID).then(res => {
            this.loading = false;
            this.studentWasDeleted = true;
            this.popToast('Student was successfully deleted.', '');
        }).catch(err => {
            console.error(err);
        });
    }



    /**
     * Pops up a toast message using Angular Material's 'SnackBar' Component.
     * @param message The message to display in the toast (snackBar)
     * @param action Optional action button text
     */
    popToast(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
            panelClass: 'toast-success'
        });
    }

    /**
     * Refresh the front end.
     */
    clearSelections() {
        this.readyToEnroll = false;
        this.selectedDiagnostic = {};
    }

    getDiagnosticType(shortName: string) {
        return this.userService.getDiagnosticName(shortName);
    }
}
