import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    email: string;
    password: string;

    constructor(private authService: AuthService, private router: Router, private snackBar: MatSnackBar) { }

    ngOnInit() {
        setTimeout(() => {
            this.checkIfLoggedIn();
        }, 1500);
    }


    /**
     * Login to Firebase Authentication
     */
    login(): void {
        this.authService.login(this.email, this.password).then(() => {
            this.router.navigate(['/resources']);
        }).catch(err => {
            this.popToast('An error occurred. Please check your email and password and try again. Error from server: \"' + err.message + '\"', '', 'toast-error');
            console.error(err);
        });
    }


    /**
     * Auth kinda shows up late, so check if user is logged in and redirect to dashbaord.
     */
    checkIfLoggedIn() {
        if (this.authService.isLoggedIn()) {
            this.router.navigate(['/resources']);
        }
    }

    /**
     * Pops up a toast message using Angular Material's 'SnackBar' Component.
     * @param message The message to display in the toast (snackBar)
     * @param action Optional action button text
     */
    popToast(message: string, action: string, style: string) {
        this.snackBar.open(message, action, {
            duration: 5000,
            panelClass: style
        });
    }
}
