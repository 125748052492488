import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../services/user.service';
import { AuthService } from '../../services/auth.service';

@Component({
    selector: 'app-results-details-page',
    templateUrl: './results-details-page.component.html',
    styleUrls: ['./results-details-page.component.scss']
})
export class ResultsDetailsPageComponent implements OnInit {

    studentFirebaseID: string;
    resultsFirebaseID: string;
    student: any;
    results: any;
    loading = true;

    constructor(public activatedRoute: ActivatedRoute, public userService: UserService, public authService: AuthService) { }

    ngOnInit() {

        this.resultsFirebaseID = this.activatedRoute.snapshot.paramMap.get('id');

        this.userService.getResultByID(this.resultsFirebaseID).then(resultRes => {
            this.results = resultRes.data();

            if (!this.results.subcategoryScores) {
                this.results['subcategoryScores'] = this.userService.calculateSubcategoryScores(this.results.subcategories, this.results.answers);
            }

            if (this.authService.isLoggedIn()) {
                this.userService.getStudentByDocRef(this.results.student).then(res => {
                    this.student = res.data();
                    this.loading = false;
                }).catch(err => {
                    this.loading = false;
                    console.error(err);
                });
            } else {
                this.loading = false;
            }
            
        }).catch(err => {
            console.error(err);
        });
    }
}
