import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'underscore';
import { UserService } from '../../services/user.service';

@Component({
	selector: 'app-resource-page',
	templateUrl: './resource-page.component.html',
	styleUrls: ['./resource-page.component.scss']
})
export class ResourcePageComponent implements OnInit {

	admin: boolean = false;

	allResources: any[] = [];
	selectedResources: any[];
	filteredResources: any[][] = [[], [], [], [], [], [], [], [], [], []];

	allSpanishResources: any[] = [];
	selectedSpanishResources: any[];
	filteredSpanishResources: any[][] = [[], [], [], [], [], [], [], [], [], []];

	selectedGrade: number = 0;
	selectedLanguage: string = 'English';
	curricMap: any;

	units = [
		['Counting and Number Sense', 'Measurement: Comparison', 'Number Operations: Part-Whole and Compare', 'Geometry: 2D Shapes and Space', 'Number Operations: Join and Separate', 'Measurement: Iteration', 'Number Operations: Place Value', 'Geometry: Composing and Comparing 2D & 3D Shapes'],
		['Building Number, Counting, and Place Value', 'Linear Measurement', 'Number Operations: Part-Whole and Compare', 'Geometry: Shape and Space', 'Number Operations: Join and Separate', 'Measurement Iteration', 'Operations and Data', 'Geometry: Composing and Decomposing Shapes'],
		['Number and Place Value', 'Linear Measurement', 'Number Operations: Part-Whole and Compare', 'Geometry: Shape and Space', 'Number Operations: Join and Separate', 'Measurement and Data', 'Number', 'Geometry'],
		['Place Value: Addition & Subtraction', 'Multiplication, Arrays and Area', 'Fraction Understanding Part 1', 'Measurement and the Four Operations', 'Multiplication, Division and Area: Part 1', 'Fraction Understanding Part 2', 'Shape Classification', 'Multiplication, Division and Area: Part 2'],
		['Number Sense and Place Value', 'Multiplication & Division', 'Equivalence and Unit Fractions', 'Application of the Four Operations', 'Geometry: Angles & Attributes', 'Decimal Fractions', 'Multiplication & Divison Fluency', 'Geometry: Symmetry & Angle Measure'],
		['Fraction Sense', 'Adding and Subtracting Fractions', 'Multiplying and Dividing Fractions', 'Patterns and Graphing', 'Decimal Understanding and Operations', 'Geometry: Volume', 'Operations with Whole Numbers and Decimals', 'Operations with Whole Numbers and Fractions', 'Classifying 2D Shapes'],
		['Proportional Reasoning', 'Fraction Division', 'Integers and Rational Numbers', 'Data Analysis: Measures of Center and Spread', 'Relationships Between Variables', 'Expressions & Equations', 'Geometry: Area, Surface Area and Volume', 'Decimal Operations', 'Standard Algorithm for Division'],
		['Proportional Reasoning', 'Scale Drawing', 'Probability and Random Sampling', 'Rational Operations', 'Operations with Expressions and Equations', 'Comparing Populations', 'Surface Area and Volume'],
		['Linear Functions', 'Comparing Linear & Non-Linear Functions', 'Linear Equations & Systems', 'Data Analysis: Bivariate Quantitative & Categorical', 'Irrational & Pythagorean', 'Geometry', 'Bivariate Categorical', 'Scientific Notation'],
		['Relationships Between Quantities', 'Linear and Exponential Functions', 'Reasoning with Expressions', 'Descriptive Statistics', 'Congruence, Proof and Constructions', 'Coordinate Geometry']
	];


	loading: boolean = false;
	agreed: boolean = true;

	constructor(private userService: UserService, private router: Router) { }

	ngOnInit() {
		this.loading = true;
		this.selectLanguage('English');

		// Check for admin status and terms agreement
		this.userService.getUserDetails().then(res => {
			let data = res.data();
			if (data.admin) {
				this.admin = data.admin;
			}
			if (data.agreed !== null && data.agreed !== undefined) {
				this.agreed = data.agreed;
			} else {
				this.agreed = false;
			}
		});

		// Grab resources
		this.userService.getResources().then(resourceResponse => {
			for (let resource of resourceResponse) {
				let data = resource.data;
				data['id'] = resource.firebaseID;
				this.allResources.push(data);
			}
			this.prepareData();
			this.loading = false;
		}).catch(err => {
			this.loading = false;
			console.error(err);
			alert('An error occurred.');
		});

		// Get Spanish resources
		this.userService.getResources(true).then(spanishResponse => {
			for (let res of spanishResponse) {
				let data = res.data;
				data['id'] = res.firebaseID;
				this.allSpanishResources.push(data);
			}
			this.sortByGrade(this.allSpanishResources, this.filteredSpanishResources);
		}).catch(err => {
			this.loading = false;
			console.error(err);
			alert('Unable to retreive Spanish resources');
		});
	}


	/**
	 * Establish our dataset by separating and sorting
	 */
	prepareData() {
		this.allResources = _.reject(this.allResources, (resource) => {
			return resource.summer === true;
		});
		this.curricMap = _.findWhere(this.allResources, {type: 'Map'});
		this.sortByGrade(this.allResources, this.filteredResources);
		this.selectedResources = [...this.filteredResources[this.selectedGrade]];
	}



	/**
	 * Organizes an array of resources into a 2D array where each initial index corresponds to
	 * the grade 0-9. Within that index is an array of all resources for that grade.
	 * @param source Source Array
	 * @param destination Destination Array for sorted Resources
	 */
	sortByGrade(source: any[], destination: any[]) {
		for (let i = 0; i < 10; i++) {
			destination[i] = _.filter(source, (resource) => {
				return resource.grades.indexOf(i) !== -1;
			});
		}
	}


	/**
	 * The event handler for changing the grade tab
	 * @param {number} grade The grade selected
	 */
	onTabChange(grade: number) {
		this.selectedGrade = grade;
		this.selectedResources = [...this.filteredResources[grade]];
		this.selectedSpanishResources = [...this.filteredSpanishResources[grade]];
	}


	/**
	 * Change the current language
	 * @param {string} language The language of resources to display. English or Spanish
	 */
	selectLanguage(language: string) {
		this.selectedLanguage = language;
	}


	/**
	 * Agree to the terms and conditions
	 */
	agree() {
		this.loading = true;
		this.userService.agreeToTerms().then(() => {
			this.agreed = true;
			this.loading = false;
		}).catch(err => {
			this.loading = false;
			console.error(err);
			alert('Something went wrong. Please refresh and try again.');
		});
	}

	editResource(resource: any) {
		this.router.navigate(['/edit-resource', resource.id], {
			state: {
				resource: resource,
				firebaseID: resource.id
			}
		});
    }

}