import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';

@Component({
    selector: 'app-create-student-page',
    templateUrl: './create-student-page.component.html',
    styleUrls: ['./create-student-page.component.scss']
})
export class CreateStudentPageComponent implements OnInit {

    loading = true;
    teacherID: string;

    firstName: string;
    lastName: string;
    studentID: string;
    grade: number;

    error: string;
    
    selectedClass: string;
    classes: any[];

    constructor(
        private userService: UserService,
        private ngFirestore: AngularFirestore,
        public router: Router
    ) { }

    ngOnInit() {
        this.userService.getUserDetails().then(res => {
            this.classes = res.data().classes;
            this.selectedClass = this.classes[0].name;
            this.loading = false;
        }).catch(err => {
            this.loading = false;
            this.error = 'We had trouble retrieving your information. Please refresh and try again. Error from server: ' + err.message;
            console.error(err);
        });
    }


    /**
     * Gather student info from input fields and add them to the database
     */
    createStudent() {
        this.error = '';
        if ((!this.firstName || !this.lastName || !this.studentID || !this.grade) || this.firstName === '' || this.lastName === '' || this.studentID === '') {
            this.error = 'All form fields are required. Check the form and try again.';
        } else {
            this.loading = true;
            const teacherDocRef = this.userService.getTeacherDocRef();
            const student = {
                firstName: this.firstName,
                lastName: this.lastName,
                grade: Number(this.grade),
                studentID: this.studentID,
                results: [],
                teacher: teacherDocRef,
                class: this.selectedClass,
                activeCodes: []
            };
            this.userService.addStudent(student).then((res) => {
                this.userService.addStudentRefToTeacher(res).then(() => {
                    this.loading = false;
                    this.router.navigate(['/ima-dashboard']);
                }).catch(err => {
                    this.error = 'Something went wrong. Check all the fields and try again. Error from server: ' + err.message;
                    console.error(err);
                    this.loading = false;
                });
            }).catch(err => {
                this.error = 'Something went wrong. Check all the fields and try again. Error from server: ' + err.message;
                this.loading = false;
                console.error(err);
            });
        }
    }
}
