import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

    email: string;
    loading = false;

    constructor(private authService: AuthService, private snackBar: MatSnackBar) { }

    ngOnInit() {
    }


    resetPassword() {
        this.loading = true;
        this.authService.resetPassword(this.email).then(() => {
            this.email = '';
            this.popToast('An email was successfully sent.', '', 'toast-success');
            this.loading = false;
        }).catch(err => {
            this.loading = false;
            console.error(err);
            this.popToast('An error occurred: ' + err.message, '', 'toast-error');
        });
    }


    /**
     * Pops up a toast message using Angular Material's 'SnackBar' Component.
     * @param message The message to display in the toast (snackBar)
     * @param action Optional action button text
     */
    popToast(message: string, action: string, style: string) {
        this.snackBar.open(message, action, {
            duration: 5000,
            panelClass: style
        });
    }
}
