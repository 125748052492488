import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'FilenameSortPipe'
})
export class FilenameSortPipe implements PipeTransform {

  transform(array: any[], ...args: any[]): any[] {
    
    return array.sort((obj1, obj2) => {
      return this.getFilename(obj1.path).localeCompare(this.getFilename(obj2.path));
    })
  }

  getFilename(path: string): string {
		return path.substring(29);
	}

}
