import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-create-class',
    templateUrl: './create-class.component.html',
    styleUrls: ['./create-class.component.scss']
})
export class CreateClassComponent implements OnInit {

    classes = [''];
    loading = false;
    classObjects: any[];
    previousNumber: number;

    constructor(private userService: UserService, private snackBar: MatSnackBar) { }

    ngOnInit() {
        this.loading = true;
        this.userService.getClasses().then(res => {
            this.classObjects = res;
            this.previousNumber = this.classObjects.length;
            this.loading = false;
        }).catch(err => {
            this.loading = false;
            console.error(err);
        });
    }



    /**
     * Submit handler for add class form
     */
    onSubmit() {
        this.prepareClassesForFirebase();
        if (this.classObjects.length !== 0) {
            this.addClassesToFirebase();
        }
    }



    /**
     * Adds another class input field to the template.
     */
    addAnotherClass() {
        this.classes.push('');
    }



    /**
     * Remove the desired class input field from the template.
     * @param index The class to remove
     */
    removeClass(index: number) {
        this.classes.splice(index, 1);
    }



    /**
     * Used in the template to provide an index in an *ngFor loop.
     * @param index 
     * @param obj 
     */
    trackByIndex(index: number, obj: any): any {
        return index;
    }



    /**
     * Calls the UserService to add the new class(es) to Firebase Firestore.
     * @param classObjects The array of class objects
     */
    addClassesToFirebase(): void {
        this.loading = true;
        this.userService.addClasses(this.classObjects).then(() => {
            this.loading = false;
            const numClasses = this.classObjects.length;
            let newClassNum = numClasses - this.previousNumber;
            this.popToast('Successfully added ' + newClassNum + (newClassNum === 1 ? ' class' : ' classes'), '', 'toast-success');
            this.classes = [''];
        }).catch(err => {
            this.loading = false;
            this.popToast(
                'ERROR: Unable to add class. Check the form and try again. ' +
                'If issue persists, refresh the page. If unable to resolve, contact DMTI.',
                '', 'toast-error');
            console.error(err);
        });
    }



    /**
     * Convert the strings to the required object structure for Firebase.
     */
    prepareClassesForFirebase() {
        for (const className of this.classes) {
            if (className !== '') {
                this.classObjects.push({
                    name: className
                });
            }
        }
    }



    /**
     * Pops up a toast message using Angular Material's 'SnackBar' Component.
     * @param message The message to display in the toast (snackBar)
     * @param action Optional action button text
     * @param styleClass The CSS class to be used. Ex: 'toast-success' or 'toast-error'.
     */
    popToast(message: string, action: string, styleClass: string) {
        this.snackBar.open(message, action, {
            duration: 5000,
            panelClass: styleClass
        });
    }

}
