import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';

@Component({
	selector: 'app-results-list',
	templateUrl: './results-list.component.html',
	styleUrls: ['./results-list.component.scss']
})
export class ResultsListComponent implements OnInit {

	@Input() student: any;
	@Input() results: any[];

	constructor() { }

	ngOnInit() {
	}

	/**
	 * Convert date object to a string.
	 * @param date Date object
	 */
	dateToString(date: any): string {
		return moment(date.seconds * 1000).calendar();
	}

}
