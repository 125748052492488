import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { ActivatedRoute, Router} from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as _ from 'underscore';



@Component({
  selector: 'app-resource-detail',
  templateUrl: './resource-detail.component.html',
  styleUrls: ['./resource-detail.component.scss']
})


export class ResourceDetailComponent implements OnInit {

  error: string;
  resource: any;
  firebaseID: any;
  title: string;
  description: string;
  type: string;
  grades: number[];
  topic: string;
  language: string;
  fileName: string;
  vimeoUrl: string;
  unitNumber: number;
  url: string;
  path: string;
  dateCreated: any;
  dateUpdated: any;

  isVimeo = false;
  loading = false;
  resourceWasDeleted = false;
  resourceFileName: string = null;

  constructor(
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private router:Router) { }

  ngOnInit() {

    this.firebaseID = this.activatedRoute.snapshot.paramMap.get('id');

    this.userService.getResource(this.firebaseID).then(resourceResponse => {
      this.resource = resourceResponse.data();
      this.title = this.resource.title;
      this.description = this.resource.description;
      this.type = this.resource.type;
      this.grades = this.resource.grades;
      this.topic = this.resource.topic;
      this.language = this.resource.language;
      this.url = this.resource.url;
      this.path = this.resource.path;
      this.dateCreated = this.resource.dateCreated;
      this.dateUpdated = this.resource.dateUpdated;
      if(this.path != null) {
        this.fileName = this.path.substring(this.path.indexOf('_') + 1);
      }
      if (this.type == 'Module'){
        this.unitNumber = this.resource.unitNum;
      }
      if (this.type == 'Video'){
        this.vimeoUrl = this.resource.vimeoUrl;
        this.isVimeo = true;
      }
    }).catch(err => {
      console.error(err);
    });
  }

  deleteResource() {
    // this.loading = true;
    // this.userService.deleteResource(this.firebaseID).then(() => {
    //   if (this.fileName !== null) {
    //     this.userService.deleteResourceFile(this.path);
    //   }
    //   this.popToast('Resource was successfully deleted.', '');
    //   this.loading = false;
    //   this.resourceWasDeleted = true;
    //   this.router.navigate(['/resource']);
    // }).catch(err => {
    //   this.loading = false;
    //   console.error(err);
    // });
  }

  /**
   * Grab the current date and time aand return as a string
   */
  getDateString(dateObject: any): string {
    return new Date(dateObject.seconds * 1000).toString();
  }

  /**
    * Pops up a toast message using Angular Material's 'SnackBar' Component.
    * @param message The message to display in the toast (snackBar)
    * @param action Optional action button text
    */
  popToast(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
      panelClass: 'toast-success'
    });
  }

}
