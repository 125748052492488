import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Router } from '@angular/router';

export interface DialogData {
    diagnostic: string;
    students: any[]
}

@Component({
    selector: 'app-student-dialog',
    templateUrl: 'student-dialog.component.html',
})

export class StudentDialogComponent {


    constructor(
        public dialogRef: MatDialogRef<StudentDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData, public router: Router) { }


    onNoClick(): void {
        this.dialogRef.close();
    }

    onSelectStudent(studentID: string) {
        this.router.navigate(['/diagnostic', this.data.diagnostic, studentID]);
        this.dialogRef.close();
    }
}
