import { Component, Input, OnChanges, OnInit, SimpleChanges, SimpleChange } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
	selector: 'app-resource-module',
	templateUrl: './resource-module.component.html',
	styleUrls: ['./resource-module.component.scss']
})
export class ResourceModuleComponent implements OnInit, OnChanges {

    @Input() module: any;
    
	videoUrls: any[] = [];
	slideConfig = {"slidesToShow": 2, "slidesToScroll": 1, "dots": true, "infinite": true};

	constructor(private sanitizer: DomSanitizer) { }

	ngOnInit() {
		this.getVideoEmbeds();
	}

	ngOnChanges(changes: SimpleChanges) {
		const currentItem: SimpleChange = changes.module;
		if (currentItem.currentValue['moduleVideos']) {
			this.module.moduleVideos = currentItem.currentValue['moduleVideos'].slice();
			this.getVideoEmbeds();
		} else {
			this.module.moduleVideos = [''];
		}
	}

	getVideoEmbeds() {
		this.videoUrls = [];
		if (this.module.moduleVideos && this.module.moduleVideos.length > 0 && this.module.moduleVideos[0] !== '') {
			for (let url of this.module.moduleVideos) {
				let videoID = new URL(url).pathname;
				this.videoUrls.push(this.sanitizer.bypassSecurityTrustResourceUrl('https://player.vimeo.com/video/' + videoID.slice(1) + '?byline=0&portrait=0'))
			}
		}
	}

	getFileLabel(path: string) {
		if (path.includes('.pdf')) {
			return 'PDF';
		} else {
			return 'Powerpoint';
		}
	}

	slickInit(e) {
	}

	breakpoint(e) {
	}
	
	afterChange(e) {
	}
	
	beforeChange(e) {
	}

	trackByIndex(index: number, videoUrl: string) {
		return videoUrl;
	}
}
