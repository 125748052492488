import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

    email: string;
    password: string;
    confirmPassword: string;
    firstName: string;
    lastName: string;
    schoolName: string;
    teacherID: string;

    loading = false;
    homeschool = false;

    error: string;

    classes = [''];
    allSchools: any;
    schools: any[];
    states: any[];
    districts: any[];
    
    selectedSchool: string;
    selectedState: string;
    selectedDistrict: string;

    constructor(private userService: UserService, private authService: AuthService, private router: Router) { }

    ngOnInit() {
        this.userService.getSchools().then(res => {
            this.allSchools = res;
            this.parseSchools();
        }).catch(err => {
            console.error(err);
        });
    }


    parseSchools() {
        this.states = Object.keys(this.allSchools);
    }

    onSelectState() {
        this.districts = Object.keys(this.allSchools[this.selectedState]);
    }

    onSelectDistrict() {
        this.schools = this.allSchools[this.selectedState][this.selectedDistrict];
    }

    /**
     * The action when the signup form gets submitted. Calls the UserService to create user auth information in Firebase.
     * Then calls createUserData().
     * @see UserService
     * @see createUserData
     */
    signup(): void {
        if (this.email && this.password && this.firstName && this.lastName && this.selectedState) {
            
            if (this.password !== this.confirmPassword) {
                this.error = 'Passwords do not match! Please re-enter them.';
            } else {
                if (!this.homeschool && (!this.selectedSchool || !this.selectedDistrict || !this.selectedSchool || this.classes.length == 0 || !this.classes[0] || this.classes[0] === '')) {
                    this.error = 'You are missing required fields';
                } else {
                    this.loading = true;
                    this.error = '';
                    this.authService.signup(this.email, this.password).then(res => {
                        let uid = res.user.uid;
                        this.createUserData(uid);
                    }).catch(err => {
                        this.error = 'Something went wrong. Check the form and try submitting again. Error from server: ' + err.message;
                        console.error(err);
                        this.loading = false;
                    });
                }
            }
        } else {
            if (this.firstName === undefined || this.firstName === '') {
                this.error = 'The first name field is required';
            } else if (this.email === undefined || this.email === '') {
                this.error = 'You need to enter an email address';
            } else if (this.password === undefined || this.password ==='') {
                this.error = 'You need to enter a password';
            } else if (this.lastName === undefined || this.lastName === '') {
                this.error = 'The last name field is required';
            } else if (this.selectedState === undefined || this.selectedState === '') {
                this.error = 'The state field is required';
            }
        }
    }


    /**
     * Gather user data and call the UserService to add it to the database.
     * @see UserService
     */
    createUserData(uid: string): void {
        this.error = '';

        if (this.teacherID === undefined) {
            this.teacherID = '';
        }

        let record = {
            teacherID: this.teacherID,
            email: this.email,
            firstName: this.firstName,
            lastName: this.lastName,
            state: this.selectedState,
            students: [],
            classes: []
        };

        if (this.homeschool) {
            record['schoolName'] = 'Homeschool';
            record['district'] = 'Homeschool';
            record['classes'] = [{ name: 'Homeschool'}];
            record['homeschool'] = true;
        } else {
            record['district'] = this.selectedDistrict;
            record['schoolName'] = this.selectedSchool;

            // Keeping class an object in case we need to add more properties in the future.
            for (let i=0; i<this.classes.length; i++) {
                record.classes.push({
                    name: this.classes[i],
                });
            }
        }

        this.userService.createNewTeacher(uid, record).then(() => {
            this.loading = false;
            this.router.navigate(['/resources']);
        }).catch(err => {
            this.loading = false;
            console.error(err);
            this.error = 'Something went wrong. Check the form and try submitting again. Error from server: ' + err.message;
        });
    }

    /**
     * Adds another class to the classes array as an empty string.
     * This generates another form field in the template
     */
    addAnotherClass() {
        this.classes.push('');
    }

    /**
     * Removes the class from the classes list
     * @param index The index of the class to remove from the list
     */
    removeClass(index: number) {
        this.classes.splice(index, 1);
    }

    /**
     * Helper function for an *ngFor in the tempate.
     * @param index 
     * @param obj 
     * @returns 
     */
    trackByIndex(index: number, obj: any): any {
        return index;
    }

    /**
     * Checks if all the form fields in the template are filled out.
     * @returns True if any form field is not filled out
     */
    formNotFilled(): boolean {
        if (this.homeschool) {
            return !this.firstName
            || !this.lastName
            || !this.selectedState
            || this.password !== this.confirmPassword
            || this.email === undefined
            || this.email === '';
        } else {
            return !this.firstName
            || !this.lastName
            || !this.selectedState
            || !this.selectedDistrict
            || !this.selectedSchool
            || !this.classes[0]
            || !(this.classes[0] !== '')
            || this.password !== this.confirmPassword
            || this.email === undefined
            || this.email === '';
        }
        
    }
}
