import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { UserService } from '../services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ExportType, MatTableExporterDirective } from 'mat-table-exporter';
import * as _ from 'lodash';
import * as firebase from 'firebase/app';
import DocumentReference = firebase.firestore.DocumentReference;

@Component({
	selector: 'app-admin',
	templateUrl: './admin.component.html',
	styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

	@ViewChild(MatSort, { static: true }) sort: MatSort;
	@ViewChild(MatTableExporterDirective, { static: true }) matTableExporter: MatTableExporterDirective;

	loading: boolean;
	filterText: string;
	allResults: any[];

	totals: any;

	displayedColumns: string[] = [
		'firstName',
		'lastName',
		'date',
		'dimension',
		'grade',
		'class',
		'00A',
		'00B',
		'01A',
		'01B',
		'02A',
		'02B',
		'03A',
		'03B',
		'04A',
		'04B',
		'05A',
		'05B',
		'06A',
		'06B',
		'07A',
		'07B',
		'08A',
		'08B',
		'09A',
		'09B',
		'10A',
		'10B',
		'11A',
		'11B',
		'12A',
		'12B',
		'13A',
		'13B',
		'14A',
		'14B',
		'15A',
		'15B',
		'16A',
		'16B',
		'17A',
		'17B',
		'18A',
		'18B',
		'19A',
		'19B'
	];

	dataSource: MatTableDataSource<any>;

	constructor(private userService: UserService, private snackBar: MatSnackBar) { }

	ngOnInit() {
		this.loading = true;

		this.userService.getAllResults().then(res => {
			this.allResults = res;
			this.buildReportTable();
		}).catch(err => {
			console.error(err);
			this.loading = false;
			this.popToast('An error occurred. Try refreshing the page.', '', 'toast-error');
		});
	}


	/**
	 * Uses student data to format an object that is fed to the reports table.
	 */
	async buildReportTable() {
		let tempResultArray: any[] = [];
		for (let i = 0; i < this.allResults.length; i++) {
			let result = this.allResults[i];
			const student: DocumentReference = result.student;
			const studentSnap = await student.get();
			const studentData = studentSnap.data();
			let finalResultObj = {
				date: this.getDateString(result.date),
				dimension: result.category,
				grade: studentData.grade,
				class: studentData.class,
				firstName: studentData.firstName,
				lastName: studentData.lastName
			}
			for (let j = 0; j < result.answers.length; j++) {
				finalResultObj[result.answers[j].questionId] = result.answers[j].isCorrect ? 1 : 0;
			}
			tempResultArray.push(finalResultObj);
		}
		this.dataSource = new MatTableDataSource(tempResultArray);
		this.dataSource.sort = this.sort;

		this.loading = false;
	}

	filterChange() {
		this.dataSource.filter = this.filterText;
	}


	/**
	   * Grab the current date and time aand return as a string
	   */
	getDateString(date: any): string {
		if (date && date.seconds) {
			return new Date(date.seconds * 1000).toLocaleDateString();
		}
		return 'unable to retreive date';
	}


	/**
	 * Pops up a toast message using Angular Material's 'SnackBar' Component.
	 * @param message The message to display in the toast (snackBar)
	 * @param action Optional action button text
	 */
	popToast(message: string, action: string, style: string) {
		this.snackBar.open(message, action, {
			duration: 3000,
			panelClass: style
		});
	}


	/**
     * Exports the angular material mat-table as a spreadsheet
     * @param type The requested file type. ('excel' or 'csv')
     */
	export(type: string) {
		const date = new Date().toDateString();
		if (type === 'excel') {
			this.matTableExporter.exportTable(ExportType.XLSX, { fileName: 'DMTI IMA Report ' + date });
		} else {
			this.matTableExporter.exportTable(ExportType.CSV, { fileName: 'DMTI IMA Report ' + date });
		}
	}


	/**
	 * Function used in the template to set table cell color
	 * @param cellData 0 or 1
	 * @returns The CSS class to use
	 */
	getTableClass(cellData: number) {
		if (cellData === 0) {
			return 'incorrect';
		}
		if (cellData === 1) {
			return 'correct';
		}
		return '';
	}

}
