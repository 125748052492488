import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-students-page',
    templateUrl: './students-page.component.html',
    styleUrls: ['./students-page.component.scss']
})

export class StudentsPageComponent implements OnInit {

    students: any[];

    constructor(private userService: UserService) { }

    ngOnInit() {
        this.userService.getStudents().then(studentRes => {
            this.students = studentRes;
        }).catch(err => {
            console.error(err);
        });
    }
}
