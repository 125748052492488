import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-bug-report-page',
	templateUrl: './bug-report-page.component.html',
	styleUrls: ['./bug-report-page.component.scss']
})
export class BugReportPageComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}
}
