import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'app-diagnostic-list',
    templateUrl: './diagnostic-list.component.html',
    styleUrls: ['./diagnostic-list.component.scss']
})
export class DiagnosticListComponent implements OnInit {

    @Input() diagnostics: any[];
    @Input() studentFirebaseID: string;
    @Output() selectDiagnostic = new EventEmitter<any>();

    diagnosticID: string;
    students: any[];

    constructor() {
    }

    ngOnInit() {
    }

    clickDiagnostic(diagnostic: any) {
        this.selectDiagnostic.emit(diagnostic);
    }
}
