import { Component, OnInit, Input } from '@angular/core';
import * as Highcharts from 'highcharts';
import { HighchartsChartComponent, HighchartsChartModule } from 'highcharts-angular';
import exporting from 'highcharts/modules/exporting';
import boxplot from 'highcharts/highcharts-more';
exporting(Highcharts);
boxplot(Highcharts);

@Component({
	selector: 'app-reports-admin-graph',
	templateUrl: './reports-admin-graph.component.html',
	styleUrls: ['./reports-admin-graph.component.scss']
})
export class ReportsAdminGraphComponent implements OnInit {

    @Input() dataset: any;
    @Input() title: string;

	fallBoxPlot: [];
	springBoxPlot: [];
	winterBoxPlot: [];

	dimensions = ['Algebraic Reasoning', 'Decimal Concepts', 'Fraction Concepts', 'Geometric Measurement', 'Multiplication & Division Fluency', 'Place Value Concepts', 'Ratio Proportion Concepts'];

	chartOptions: Highcharts.Options;
    highcharts = Highcharts;
    chartConstructor = 'chart';

	constructor() { }

	ngOnInit() {
        this.buildGraph();
	}


	/**
	  * Create options object for highcharts graph data
	  */
	buildGraph(): void {

		this.chartOptions = {
			exporting: {
				chartOptions: {
					plotOptions: {
						series: {
							dataLabels: {
								enabled: true
							}
						}
					}
				},
				fallbackToExportServer: false
			},

			accessibility: {
				point: {
					valueDescriptionFormat: '{index}. {xDescription}, {point.y}.'
				}
			},

			legend: {
				enabled: false
			},

			subtitle: {
                text: 'By Semester & Dimension',
                align: 'left'
			},

			title: {
                text: this.title,
                align: 'left'
			},

			tooltip: {
				shared: true
			},

			xAxis: {
				categories: this.dimensions
			},
			yAxis: {
				min: 0,
				max: 100,
				title: {
					text: 'Average Score (Percent)'
				}
			},
			plotOptions: {
				boxplot: {
                    dataSorting: {
                        enabled: false
                    }
                }
			},
			credits: {
				enabled: false
			},
			series: [
				{
					type: 'boxplot',
					name: 'Fall',
					data: [
						this.dataset.fall[0],
						this.dataset.fall[1],
						this.dataset.fall[2],
						this.dataset.fall[3],
						this.dataset.fall[4],
						this.dataset.fall[5],
						this.dataset.fall[6],
					],
					tooltip: {
						headerFormat: '<em>{point.key} Box Plots</em><br/>'
					}
				},
				{
					type: 'boxplot',
					name: 'Winter',
					data: [
						this.dataset.winter[0],
						this.dataset.winter[1],
						this.dataset.winter[2],
						this.dataset.winter[3],
						this.dataset.winter[4],
						this.dataset.winter[5],
						this.dataset.winter[6]
					],
					tooltip: {
						headerFormat: '<em>{point.key} Box Plots</em><br/>'
					}
				},
				{
					type: 'boxplot',
					name: 'Spring',
					data: [
						this.dataset.spring[0],
						this.dataset.spring[1],
						this.dataset.spring[2],
						this.dataset.spring[3],
						this.dataset.spring[4],
						this.dataset.spring[5],
						this.dataset.spring[6]
					],
					tooltip: {
						headerFormat: '<em>{point.key} Box Plots</em><br/>'
					}
				},
			]

		};
    }

}
