import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import * as _ from 'underscore';

@Component({
	selector: 'app-supplemental-list',
	templateUrl: './supplemental-list.component.html',
	styleUrls: ['./supplemental-list.component.scss']
})
export class SupplementalListComponent implements OnInit {

	@Input() resources: any[];
	@Input() admin: boolean;

	_grade: number;

	@Input() set grade(value: number) {
		this._grade = value;
		this.sortResources();
	}
	get grade(): number {
		return this._grade;
	}

	@Output() edit = new EventEmitter();

	topics = ['DMTI Overviews', 'Fluency & Flexibility', 'Measurement', 'Data', 'Geometry', 'Number Primary', 'Number Intermediate', 'Number Middle School', 'Algebraic Reasoning'];

	sortedResources = {
		'DMTI Overviews': [],
		'Fluency & Flexibility': [],
		'Measurement': [],
		'Data': [],
		'Geometry': [],
		'Number Primary': [],
		'Number Intermediate': [],
		'Number Middle School': [],
		'Algebraic Reasoning': []
	}

	constructor() { }

	ngOnInit() {
		this.sortResources();
	}

	/**
	 * Tells the parent component that the resource needs to be edited.
	 * Parent component should navigate to /edit-resource/{id}
	 * @param resource The resource to be edited
	 */
	editResource(resource: any) {
		this.edit.emit(resource);
	}

	/**
	 * Sorts resources into categories by topic
	 */
	sortResources() {
		this.sortedResources = {
			'DMTI Overviews': [],
			'Fluency & Flexibility': [],
			'Measurement': [],
			'Data': [],
			'Geometry': [],
			'Number Primary': [],
			'Number Intermediate': [],
			'Number Middle School': [],
			'Algebraic Reasoning': []
		}
		for (let topic of this.topics) {
			let topicArray = _.filter(this.resources, (resource) => {
				return _.contains(resource.topic, topic)
			});
			this.sortedResources[topic] = topicArray;
		}
	}

}
