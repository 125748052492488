import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { AuthService } from '../../services/auth.service';

@Component({
	selector: 'app-edit-teacher',
	templateUrl: './edit-teacher.component.html',
	styleUrls: ['./edit-teacher.component.scss']
})
export class EditTeacherComponent implements OnInit {

	loading = true;
	changesMade = false;
	homeschool = false;

	firstName: string;
	lastName: string;
	currentEmail: string;
	passwordReauth: string;
	teacherID: string;
	selectedSchool: string;
	selectedState: string;
	selectedDistrict: string;

	email1 = '';
	email2 = '';

	allSchools: any;
	schools: any[];
	states: any[];
	districts: any[];

	constructor(private userService: UserService, private authService: AuthService) { }

	ngOnInit() {
		this.userService.getUserDetails().then(res => {
			const teacherData = res.data();
			this.firstName = teacherData.firstName;
			this.lastName = teacherData.lastName;
			this.currentEmail = teacherData.email;
			this.teacherID = teacherData.teacherID;
			this.homeschool = teacherData.homeschool;

			if (this.homeschool === false || this.homeschool === undefined || this.homeschool === null) {
				this.userService.getSchools().then(schoolsRes => {
					this.allSchools = schoolsRes;
					this.parseSchools();
	
					if (teacherData.state) {
						this.selectedState = teacherData.state;
						this.onSelectState();
					}
					if (teacherData.district) {
						this.selectedDistrict = teacherData.district;
						this.onSelectDistrict();
					}
					if (teacherData.schoolName) {
						if (this.schools && this.schools.includes(teacherData.schoolName)) {
							this.selectedSchool = teacherData.schoolName;
						}
					}
					this.loading = false;
				}).catch(err => {
					console.error(err);
					this.loading = false;
					alert('Something went wrong while retrieving your data. Please refresh and try again.');
				});
			} else {
				this.loading = false;
			}
		}).catch(err => {
			console.error(err);
			this.loading = false;
			alert('Something went wrong while retrieving your data. Please refresh and try again.');
		});
	}

	parseSchools() {
		this.states = Object.keys(this.allSchools);
	}

	onSelectState() {
		this.districts = Object.keys(this.allSchools[this.selectedState]);
	}

	onSelectDistrict() {
		this.schools = this.allSchools[this.selectedState][this.selectedDistrict];
	}

	updateTeacher() {
		this.loading = true;
		
		this.userService.editTeacher({
			state: this.selectedState,
			district: this.selectedDistrict,
			schoolName: this.selectedSchool,
			firstName: this.firstName,
			lastName: this.lastName,
			teacherID: this.teacherID
		}).then(() => {
			this.loading = false;
			alert('Your data was successfully updated.');
		}).catch(err => {
			this.loading = false;
			alert('An error occurred while saving your data. Please refresh the page and try again.');
			console.error(err);
		});
	}


	updateEmail() {
		this.loading = true;
		this.authService.reAuth(this.passwordReauth).then(() => {
			if (this.email1 !== '' && this.email1 === this.email2) {
				this.authService.changeEmail(this.email1).then(res => {
					this.userService.editTeacher({
						email: this.email1
					}).then(() => {
						this.currentEmail = this.email1.valueOf();
						this.email1 = '';
						this.email2 = '';
						this.loading = false;
						alert('Your email was successfully updated');
					}).catch(err => {
						this.loading = false;
						alert('an error occurred. please refresh and try again.');
						console.error(err);
					});
				}).catch(err => {
					this.loading = false;
					alert('an error occurred. please refresh and try again.');
					console.error(err);
				});
			}
		}).catch(err => {
			this.loading = false;
			alert('An error occurred. Your password might have been incorrect. Please refresh and try again.');
			console.error(err);
		});
	}

	buttonIsDisabled() {
		return !(this.selectedState && this.selectedDistrict && this.selectedSchool && this.firstName && this.lastName && this.changesMade);
	}

}
