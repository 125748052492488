import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../services/user.service';
import { DiagnosticService } from '../../services/diagnostic.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DocumentReference } from '@angular/fire/firestore';
import * as _ from 'underscore';

@Component({
    selector: 'app-class-detail',
    templateUrl: './class-detail.component.html',
    styleUrls: ['./class-detail.component.scss']
})

export class ClassDetailComponent implements OnInit {

    className: string;
    selectedDiagnostic: any;
    studentToDelete: any;

    showConfirmModal = false;
    readyToEnroll = false;
    generatingDiagnostic = false;
    removingDiagnostics = false;
    anyActiveStudents = false;
    loading: boolean;

    students: any[];
    activeDiagnostics: any[];
    diagnostics: any[];

    teacherRef: DocumentReference;

    constructor(
        private activatedRoute: ActivatedRoute,
        private userService: UserService,
        private diagService: DiagnosticService,
        private snackBar: MatSnackBar
    ) { }

    ngOnInit() {
        this.loading = true;
        // Get the classname from URL and replace hyphens with spaces
        this.className = this.activatedRoute.snapshot.paramMap.get('name');
        this.className = decodeURIComponent(this.className);

        this.diagnostics = this.diagService.getQuizList();
        this.teacherRef = this.userService.getTeacherDocRef();

        this.userService.getStudentsByClassAndTeacher(this.className, this.teacherRef).then(studentResponse => {
            if (studentResponse) {
                this.students = _.sortBy(studentResponse, (student) => {
                    return student.data.lastName;
                });
                this.findActiveStudents();
            }
            this.loading = false;
        }).catch(err => {
            this.loading = false;
            console.error(err);
        });
    }



    /**
     * Loop through the class's students and find the ones with
     * an active diagnostic code.
     */
    findActiveStudents(): void {
        this.anyActiveStudents = false;
        for (let student of this.students) {
            let code = student.data.activeCodes[0];
            if (code !== null && code !== undefined && code !== '') {
                this.anyActiveStudents = true;
                break;
            }
        }
    }



    /**
     * Loop through all students and add them to an active diagnostic window.
     */
    enrollClass() {
        this.generatingDiagnostic = true;
        
        this.userService.addClassCodes(this.students, this.selectedDiagnostic).then(studentsRes => {
            for (let i=0; i<this.students.length; i++) {
                let code = studentsRes[i].data.activeDiagnostic;
                this.students[i].data.activeDiagnostic = code;
            }
            this.findActiveStudents();
            this.generatingDiagnostic = false;
        }).catch(err => {
            console.error(err);
            this.generatingDiagnostic = false;
        });
    }

    



    /**
     * Recursive function that generates a code and checks if it's already in Firebase.
     * If it is, it tries again. If not, it adds the code and associated student ID.
     * @param studentID string - The ID of the student
     * @param grade number - The student's grade
     */
    enrollStudent(student: any, diagnostic: any) {
        this.userService.addCode(student, diagnostic).then(studentRes => {
            student.data.activeDiagnostic = studentRes.data.activeDiagnostic;
        }).catch(err => {
            console.error(err);
        });
    }



    /**
     * Click handler for selecting the diagnostic to enroll the class in.
     * @param diagnostic The selected Diagnostic
     */
    onSelectDiagnostic(diagnostic: any) {
        this.selectedDiagnostic = diagnostic;
        this.readyToEnroll = true;
    }



    /**
     * Refresh the front end.
     */
    refreshStudents() {
        this.loading = true;
        this.students.splice(0, this.students.length);
        this.readyToEnroll = false;
        this.selectedDiagnostic = {};

        this.userService.getStudentsByClassAndTeacher(this.className, this.teacherRef).then(res => {
            this.students = res;
            this.findActiveStudents();
            this.generatingDiagnostic = false;
            this.removingDiagnostics = false;
            this.loading = false;
        }).catch(err => {
            console.error(err);
            this.popToast('An error occurred. Please refresh the page', '', 'toast-error');
            this.loading = false;
        });
    }




    /**
     * Use the UserService to remove the active diagnostic code from the activeDiagnostics collection
     * and remove it from the student's activeDiagnostic field.
     * @param code string - The active diagnostic code to be removed
     * @param student any - The student object used to update the front end
     */
    removeCode(student: any, codeObject: any) {
        this.removingDiagnostics = true;

        this.userService.removeCode(student, codeObject).then(() => {
            student.data.activeCodes = _.reject(student.data.activeCodes, (codeObj: any) => {
                return _.isEqual(codeObj, codeObject);
            });
            this.removingDiagnostics = false;
        }).catch(err => {
            console.error(err);
            this.removingDiagnostics = false;
            this.popToast('An error occurred: ' + err, '', 'toast-error');
        });

    }



    /**
     * Sets values back to default.
     */
    resetSelectedDiagnostic() {
        this.selectedDiagnostic = {};
        this.readyToEnroll = false;
    }



    // THIS IS NOT CURRENTLY USED UNTIL WE CAN ENSURE IDEMPOTENCE IN FIREBASE FUNCTIONS
    removeAllDiagnostics() {
        // this.removingDiagnostics = true;
        // const promises = [];
        // for (const student of this.students) {
        //     for (const code of student.activeDiagnostics) {
        //         const promise = new Promise((resolve, reject) => {
        //             this.removeCode(code, student);
        //         });
        //         promises.push(promise);
        //     }
        // }
        // Promise.all(promises).then(() => {
        //     this.refreshStudents();
        //     this.removingDiagnostics = false;
        // }).catch(err => {
        //     console.error(err);
        // });
    }


    /**
     * Click handler for clicking the 'delete' button in the student list
     * @param student The student object from Firebase
     */
    selectStudentToDelete(student: any) {
        this.studentToDelete = student;
    }


    /**
     * Calls the user service to add the 'deleted: true' property to the student in Firebase.
     * Then removes the student from the array being rendered in the UI.
     */
    deleteStudent() {
        this.userService.deleteStudent(this.studentToDelete.firebaseID).then(res => {
            this.students = _.reject(this.students, (student: any) => {
                return student.firebaseID === this.studentToDelete.firebaseID;
            });
            this.popToast('Student was successfully deleted.', '', 'toast-success');
        }).catch(err => {
            console.error(err);
        });
    }


    /**
     * Opens the browser's print dialog.
     */
    print(): void {
        window.print();
    }



    /**
     * Pops up a toast message using Angular Material's 'SnackBar' Component.
     * @param message The message to display in the toast (snackBar)
     * @param action Optional action button text
     * @param style The CSS class to use
     */
    popToast(message: string, action: string, style: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
            panelClass: style
        });
    }


    getDiagnosticType(shortName: string) {
        return this.userService.getDiagnosticName(shortName);
    }
}
