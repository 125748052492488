import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UserService } from 'src/app/services/user.service';
import { DiagnosticService } from 'src/app/services/diagnostic.service';
import * as _ from 'underscore';

@Component({
    selector: 'app-student-list',
    templateUrl: './student-list.component.html',
    styleUrls: ['./student-list.component.scss']
})
export class StudentListComponent implements OnInit {

    @ViewChild(MatSort, { static: true }) sort: MatSort;

    @Input() students: any[];
    @Input() showDiagnosticBtn: boolean;

    @Output() clickDiagnostic = new EventEmitter();
    @Output() clickDelete = new EventEmitter();
    @Output() enrollStudents = new EventEmitter();
    @Output() codeRemove = new EventEmitter();

    loading: boolean = true;
    filterText: string;

    filteredStudents: any[];
    classes: any[];
    selectedClasses: any[];

    selectedDiagnostic: any;
    showConfirmModal = false;
    readyToEnroll = false;
    diagnostics: any[];

    displayedColumns: string[] = [
        'report',
        'last',
        'first',
        'class',
        'grade',
        'activeCodes',
        'edit'
    ];

    dataSource: MatTableDataSource<any>;

    constructor(private userService: UserService) { }

    ngOnInit() {
        this.userService.getClasses().then(res => {
            this.classes = _.pluck(res, 'name');
            this.selectedClasses = this.classes.slice();
        }).catch(err => {
            alert('Something went wrong while retrieving your classes. Please try refreshing the page');
            console.error(err);
        });
        this.filteredStudents = this.students.slice();
        this.buildStudentTable();
    }

    enrollStudent(student: any) {
        this.clickDiagnostic.emit(student);
    }

    enrollSelectedStudents() {
        this.enrollStudents.emit(this.filteredStudents);
    }

    delete(student: any) {
        this.clickDelete.emit(student);
    }

    filterStudents() {
        this.filteredStudents = _.filter(this.students, (student) => {
           return _.contains(this.selectedClasses, student.data.class);
        });
    }

    /**
	 * Uses student data to format an object that is fed to the reports table.
	 */
	buildStudentTable() {
        this.loading = true;
		let tempStudentArray: any[] = [];
		for (let i = 0; i < this.filteredStudents.length; i++) {
			let student = this.filteredStudents[i];

			let finalStudentObj = {
                id: student.firebaseID,
				grade: student.data.grade,
				class: student.data.class,
				first: student.data.firstName,
				last: student.data.lastName,
                numResults: student.data.results.length
			};
            if(student.data.activeCodes) {
                finalStudentObj['activeCodes'] = student.data.activeCodes;
                finalStudentObj['numCodes'] = student.data.activeCodes.length;
            }
			tempStudentArray.push(finalStudentObj);
		}
		this.dataSource = new MatTableDataSource(tempStudentArray);
		this.dataSource.sort = this.sort;

		this.loading = false;
	}

    filterChange() {
		this.dataSource.filter = this.filterText;
	}

    selectClasses() {
        this.filterStudents();
        this.buildStudentTable();
    }

    /**
     * Sets values back to default.
     */
     resetSelectedDiagnostic() {
        this.selectedDiagnostic = {};
        this.readyToEnroll = false;
    }

    getDiagnosticType(shortName: string) {
        return this.userService.getDiagnosticName(shortName);
    }

    removeCode(student: any, code: string) {
        this.codeRemove.emit({student, code})
    }
}
