import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { firestore } from 'firebase';
import { AuthService } from './auth.service';
import * as _ from 'underscore';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    userDetails: any;

    diagnostics = {
        'ima-ar': 'Algebraic Reasoning',
        'ima-dc': 'Decimal Concepts',
        'ima-fc': 'Fraction Concepts',
        'ima-gm': 'Geometric Measurement',
        'ima-mdf': 'Multi/Div Fluency',
        'ima-pvc': 'Place Value Concepts',
        'ima-rpc': 'Ratio & Proportion Concepts'
    };

    constructor(private ngFirestore: AngularFirestore, private authService: AuthService, private storage: AngularFireStorage) { }



    ////////////////////////////////////////////////////////////
    ///////////////////   TEACHER API   ////////////////////////
    ////////////////////////////////////////////////////////////

    /**
     * Create new teacher data with a Firebase doc ID that matches their ID in our Firebase Auth system.
     * @param teacherObject The teacher's new data to save
     */
    createNewTeacher(teacherAuthID: string, teacherObject: any): Promise<any> {
        return this.ngFirestore.collection('teachers').doc(teacherAuthID).set(teacherObject);
    }

    /**
     * Update the teachers information in Firebase Firestore
     * @param teacherObject An object containing firstName, lastName, teacherID, district, state, and schoolName
     */
    editTeacher(teacherObject: any) {
        return this.ngFirestore.collection('teachers').doc(this.authService.getUserId()).ref.update(teacherObject);
    }


    /**
     * Take the teacher's firebase ID and return a Firestore Document Reference.
     */
    getTeacherDocRef(): firestore.DocumentReference {
        return this.ngFirestore.collection('teachers').doc(this.authService.getUserId()).ref;
    }



    /**
     * Return an object containing the currently logged in teacher's data.
     */
    async getUserDetails() {
        return this.ngFirestore.collection('teachers').doc(this.authService.getUserId()).get().toPromise();
    }


    /**
     * Retrieve all the teacher's classes from Firebase
     * @returns {any[]} An array of class objects
     */
    async getClasses(): Promise<any[]> {
        return this.ngFirestore.collection('teachers').doc(this.authService.getUserId()).ref.get().then(res => {
            return res.data().classes;
        });
    }


    /**
     * Add an array of classes to the teacher's data
     * @param {any[]} classArray The array of classes to add
     * @returns {Promise<void>}
     */
    addClasses(classArray: any[]): Promise<void> {
        const teacherRef = this.ngFirestore.collection('teachers').doc(this.authService.getUserId()).ref;
        return teacherRef.update({
            classes: classArray
        });
    }


    /**
     * Retrieves the entire schools collection from Firebase
     * @returns {any} An object representing the schools collection in Firebase
     */
    async getSchools() {
        const schools = {};
        const collectionRef = this.ngFirestore.collection('schools').ref;
        const querySnap = await collectionRef.get();
        if (querySnap.empty) {
            return null;
        } else {
            for (const res of querySnap.docs) {
                schools[res.id] = res.data();
            }
            return schools;
        }
    }


    /**
     * Saves in Firestore that the user has agreed to the terms and conditions.
     * @returns Empty Promise
     */
    agreeToTerms() {
        const teacherRef = this.ngFirestore.collection('teachers').doc(this.authService.getUserId()).ref;
        return teacherRef.update({
            agreed: true
        });
    }


    async getReport(school: string) {
        const reports = {}
        const reportRef = this.ngFirestore.collection('reports').doc(school).collection('teachers').ref;
        const reportSnap = await reportRef.get();
        if (reportSnap.empty) {
            return null;
        } else {
            for (const res of reportSnap.docs) {
                reports[res.id] = res.data().results;
            }
            return reports;
        }
    }


    ///////////////////////////////////////////////////////////////
    ////////////////////   STUDENT API   //////////////////////////
    ///////////////////////////////////////////////////////////////

    /**
     * Return student data by querying Firebase using the student's user-inputted student ID.
     * @param studentID the user-inputted student ID, not the Firebase ID
     */
    async getStudentByID(studentID: string) {
        const studentRef = this.ngFirestore.collection('students').doc(studentID);
        return studentRef.get().toPromise();
    }



    /**
     * Return the data for the desired student.
     * @param studentFirebaseID The Firebase ID of the desired student
     */
    async getStudentByDocument(studentFirebaseID: string) {
        const docSnap = await this.ngFirestore.collection('students').doc(studentFirebaseID).get();
        return docSnap.toPromise();
    }



    /**
     * Return the data for the desired student.
     * @param docRef The document reference for the desired student
     */
    getStudentByDocRef(docRef: DocumentReference) {
        return docRef.get();
    }



    /**
     * Save a new student to Firebase. Must call addStudentRefToTeacher() after this.
     * @param teacherFirebaseID the firebase document ID of the student's teacher
     * @param studentObject the student data to save
     * @see addStudentRefToTeacher
     */
    addStudent(studentObject: any) {
        return this.ngFirestore.collection('students').add(studentObject);
    }


    /**
     * Add a student document reference to the teacher's array of students.
     * Must be called after addStudent()
     * @param studentRef The student's document reference
     * @see addStudent
     */
    addStudentRefToTeacher(studentRef: DocumentReference) {
        return this.ngFirestore.collection('teachers').doc(this.authService.getUserId()).update({
            students: firestore.FieldValue.arrayUnion(studentRef)
        });
    }


    /**
     * Update the data for the desired student.
     * @param studentFirebaseID the Firebase document ID of the student to update
     * @param studentObject the new data to be added
     */
    updateStudent(studentFirebaseID: string, studentObject: any) {
        return this.ngFirestore.doc('students/' + studentFirebaseID).update(studentObject);
    }



    /**
     * Delete a student's data from Firebase
     * IMPORTANT: This does not delete their references from the teacher or their results.
     * We are filtering out deleted students on the front end.
     * @param studentFirebaseID the Firebase document ID of the student to delete
     */
    deleteStudent(studentFirebaseID: string) {
        const studentRef = this.ngFirestore.collection('students').doc(studentFirebaseID).ref;

        return studentRef.update({
            deleted: true
        });
       
    }



    /**
     * Return all students' data for the currently logged in teacher.
     */
    async getStudents() {
        const allStudents = [];
        let students: any[];
        const teacherAuthID = this.authService.getUserId();
        const teacherRef = this.ngFirestore.collection('teachers').doc(teacherAuthID).ref;
        const collectionRef = this.ngFirestore.collection('students');
        const querySnap = await collectionRef.ref.where('teacher', '==', teacherRef).get();
        if (querySnap.empty) {
            return null;
        } else {
            for (const res of querySnap.docs) {
                allStudents.push({
                    data: res.data(),
                    firebaseID: res.id
                });
            }
            students = this.removeDeletedStudents(allStudents);
            return students;
        }
    }



    /**
     * Query the database for students that have the matching className in their 'class' field
     * and the matching teacher reference in their 'teacher' field.
     * @param className The user-inputted class name
     * @param teacherRef Firestore document reference for the teacher
     */
    async getStudentsByClassAndTeacher(className: string, teacherRef: DocumentReference) {
        let allStudents = [];
        let students: any[];
        const collectionRef = this.ngFirestore.collection('students');
        const querySnap = await collectionRef.ref.where('teacher', '==', teacherRef).where('class', '==', className).get();
        if (querySnap.empty) {
            return null;
        } else {
            for (const res of querySnap.docs) {
                allStudents.push({
                    data: res.data(),
                    firebaseID: res.id
                });
            }
            students = this.removeDeletedStudents(allStudents);
            return students;
        }
    }


    /**
     * Removes students with the 'deleted' property from an array
     * @param students The array of students
     */
    removeDeletedStudents(students: any[]) {
        return _.reject(students, (student: any) => {
            return student.data.deleted == true;
        });
    }



    /**
     * Checks the database to see if the generated code is already taken.
     * @param code The generated code to check
     */
    ifCodeExists(code: string): Promise<firestore.DocumentSnapshot> {
        return this.ngFirestore.collection('activeDiagnostics').doc(code).get().toPromise();
    }


    /**
     * Recursive function that randomly generate a 5-digit alphanumeric code.
     * If the code already exists in the database, it tries again.
     */
    async createAndCheckCode() {
        const code = Math.random().toString(36).replace('0.', '').substr(0, 5);
        const codeIsTaken = (await this.ifCodeExists(code)).exists;
        if (codeIsTaken) {
            this.createAndCheckCode();
        }
        return code;
    }


    /**
     * Creates docs in the activeDiagnostics collection for every student passed in the allStudents array
     * @param allStudents List of students to add codes to
     * @param diagnostic The type of diagnostic to create codes for
     * @returns The array of students with their activeDiagnostic properties set
     */
    async addClassCodes(allStudents: any[], diagnostic: any): Promise<any[]> {
        let batch = this.ngFirestore.firestore.batch();

        for (let i=0; i < allStudents.length; i++) {
            let student = allStudents[i];
            let grade = student.data.grade;
            let currentTime = new Date();

            if (diagnostic.grades.includes(grade)) {
                const code: string = await this.createAndCheckCode();
                let codeRef = this.ngFirestore.collection('activeDiagnostics').doc(code).ref;
                const studentRef = this.ngFirestore.collection('students').doc(student.firebaseID).ref;
                
                // If the student has an active codes field and it contains at least one code, check if they already have a diagnostic of that type
                if (student.data.activeCodes && student.data.activeCodes.length > 0) {

                    // Only add a code if they don't already have one of that type
                    if (!_.some(student.data.activeCodes, (code) => { return code.type === diagnostic.queryParam})) {

                        student.data.activeCodes.push({
                            code: code,
                            type: diagnostic.queryParam,
                            timeStarted: currentTime
                        });
                        const record = {
                            student: studentRef,
                            teacher: student.data.teacher,
                            grade: student.data.grade,
                            diagnostic: diagnostic.queryParam,
                            timeStarted: firestore.FieldValue.serverTimestamp()
                        };
                        batch.set(codeRef, record);
                    }

                } else {
                    student.data['activeCodes'] = new Array({
                        code: code,
                        type: diagnostic.queryParam,
                        timeStarted: currentTime
                    });

                    const record = {
                        student: studentRef,
                        teacher: student.data.teacher,
                        grade: student.data.grade,
                        diagnostic: diagnostic.queryParam,
                        timeStarted: firestore.FieldValue.serverTimestamp()
                    };
                    batch.set(codeRef, record);
                }

                if (i === allStudents.length - 1) {
                    await batch.commit();
                    return allStudents;
                }
            }
        }
        return null;
    }


    /**
     * Generates and saves a code to the activeDiagnostics collection. Upon this event,
     * firebase functions will add the code to the student's document.
     * @param student The student enrolling in the diagnostic
     * @param diagnostic The diagnostic to generate a code for
     * @returns student object with the activeDiagnostic set to the code
     */
    async addCode(student: any, diagnostic: any): Promise<any> { 
        const grade = student.data.grade;
        const code: string = await this.createAndCheckCode();
        const codeRef = this.ngFirestore.collection('activeDiagnostics').doc(code).ref;

        if (diagnostic.grades.includes(grade)) {
            const studentRef = this.ngFirestore.collection('students').doc(student.firebaseID).ref;
            const currentTime = new Date();

            // If the student has an active codes field and it contains at least one code, check if they already have a diagnostic of that type
            if (student.data.activeCodes && student.data.activeCodes.length > 0) {

                // Only add a code if they don't already have one of that type, else do nothing
                const diagnosticTypeExists = _.some(student.data.activeCodes, (code) => { return code.type === diagnostic.queryParam});

                if (!diagnosticTypeExists) {
                    student.data.activeCodes.push({
                        code: code,
                        type: diagnostic.queryParam,
                        timeStarted: currentTime
                    });
                    const record = {
                        student: studentRef,
                        teacher: student.data.teacher,
                        grade: student.data.grade,
                        diagnostic: diagnostic.queryParam,
                        timeStarted: firestore.FieldValue.serverTimestamp()
                    };
                    await codeRef.set(record);
                }

            } else {
                student.data['activeCodes'] = new Array({
                    code: code,
                    type: diagnostic.queryParam,
                    timeStarted: currentTime
                });

                const record = {
                    student: studentRef,
                    teacher: student.data.teacher,
                    grade: student.data.grade,
                    diagnostic: diagnostic.queryParam,
                    timeStarted: firestore.FieldValue.serverTimestamp()
                };
                await codeRef.set(record);
            }
            return student;
        }
        return null;
    }




    /**
     * Removes the desired code from the activeDiagnostics collection. That will trigger
     * Firebase Functions to remove the code from the student's document.
     * If the code doesn't exist, something went wrong and we need to remove it from the student's doc
     * manually here.
     * @param student The student whose code needs to be removed
     * @param codeObject The active diagnostic object to be removed
     */
    async removeCode(student: any, codeObject: any) {
        let code = codeObject.code;
        const codeExists = (await this.ifCodeExists(code)).exists;
        if (codeExists) {
            return this.ngFirestore.collection('activeDiagnostics').doc(code).delete();
        } else {
            const studentRef = this.ngFirestore.collection('students').doc(student.firebaseID).ref;
            let activeCodes = _.reject(student.activeCodes, (codeObj: any) => {
                return _.isEqual(codeObj, codeObject);
            });
            return studentRef.update({
                activeCodes: activeCodes
            });
        }
    }



    /**
     * This function is strictly for removing the code from the collection, which will
     * trigger Firebase Functions to remove it from the student.
     * We can't manually do it after the student takes the quiz because they are not logged in
     * and therefore don't have permissions to access the student data collection.
     * @param code The code to remove
     */
    async removeCodeAfterQuiz(code: string) {
        const codeExists = (await this.ifCodeExists(code)).exists;
        if (codeExists) {
            return this.ngFirestore.collection('activeDiagnostics').doc(code).delete();
        }
    }




    ////////////////////////////////////////////////////////////////
    //////////////////////// RESULTS API ///////////////////////////
    ////////////////////////////////////////////////////////////////

    /**
     * Save the diagnostic results to the database with a reference to the student who took it.
     * IMPORTANT: call addResultToStudent() after using this method.
     * @see addResultToStudent
     * @param resultObject The results of the diagnostic
     * @param studentFirebaseID The student's Firebase document ID
     */
    saveResults(resultObject: any, studentRef: DocumentReference, teacherRef: DocumentReference) {

        const subcategoryScores = this.calculateSubcategoryScores(resultObject.subcategories, resultObject.answers);

        return this.ngFirestore.collection('results').add({
            answers: resultObject.answers,
            finalTime: resultObject.finalTime,
            percentCorrect: resultObject.percentCorrect,
            numberCorrect: resultObject.numberCorrect,
            numberOfQuestions: resultObject.numberOfQuestions,
            category: resultObject.category,
            subcategories: resultObject.subcategories,
            subcategoryScores,
            date: firestore.FieldValue.serverTimestamp(),
            student: studentRef,
            teacher: teacherRef,
            grade: resultObject.grade
        });
    }


    /**
     * Creates grades per subcategory for a diagnostic.
     * @param subcategories The list of subcategories from the diagnostic
     * @param answers The student's responses to the diagnostic questions
     */
    calculateSubcategoryScores(subcategories: string[], answers: any[]): any {
        const subcatScores = {};

        for (let subcat of subcategories) {
            subcatScores[subcat] = {
                numQuestions: 0,
                numCorrect: 0
            }
        }
        
        for (let answer of answers) {
            subcatScores[answer.subcategory].numQuestions++;
            if (answer.isCorrect) {
                subcatScores[answer.subcategory].numCorrect++;
            }
        }
        return subcatScores;
    }



    /**
     * Save a reference to a saved diagnostic result to the student who took it.
     * IMPORTANT: This should only and ALWAYS be used after saveResults()
     * @see saveResults
     * @param studentFirebaseID 
     * @param resultsFirebaseID 
     */
    addResultToStudent(studentFirebaseID: string, resultsFirebaseID: string) {
        const resultDocRef = this.ngFirestore.collection('results').doc(resultsFirebaseID);
        const studentDocRef = this.ngFirestore.collection('students').doc(studentFirebaseID);
        return studentDocRef.update({
            results: firestore.FieldValue.arrayUnion(resultDocRef.ref)
        });
    }



    /**
     * Return the desired diagnostic results data by using the Firebase document ID.
     * @param resultFirebaseID the Firebase doc ID of the desired diagnostic results
     */
    getResultByID(resultFirebaseID: string) {
        const resultDocRef = this.ngFirestore.collection('results').doc(resultFirebaseID);
        return resultDocRef.get().toPromise();
    }



    /**
     * Returns the data for the desired result.
     * @param resultDocRef The Firestore DocumentReference pointing to the result
     */
    getResultByDocRef(resultDocRef: DocumentReference) {
        return resultDocRef.get();
    }



    /**
     * Using the student's Firebase document ID, query the database to return all historical
     * diagnostic results data.
     * @param studentID the Firebase document ID of the student
     */
    async getAllResultsForStudent(studentID: string) {
        const results = [];
        const studentRef = this.ngFirestore.collection('students').doc(studentID);
        const collectionRef = this.ngFirestore.collection('results');
        const querySnap = await collectionRef.ref.where('student', '==', studentRef.ref).get();
        if (querySnap.empty) {
            return null;
        } else {
            for (const res of querySnap.docs) {
                results.push({
                    data: res.data(),
                    id: res.id
                });
            }
            return results;
        }
    }




    /**
     * Finds a student's results for a specific diagnostic category.
     * @param studentID The student's Firebase ID
     * @param category The diagnostic category
     */
    async queryResultsByStudentAndCategory(studentID: string, category: string) {
        const results = [];
        const collectionRef = this.ngFirestore.collection('results');
        const studentRef = this.ngFirestore.collection('students').doc(studentID);
        const querySnap = await collectionRef.ref.where('student', '==', studentRef.ref).where('category', '==', category).get();
        if (querySnap.empty) {
            return null;
        } else {
            for (const res of querySnap.docs) {
                results.push({
                    data: res.data(),
                    id: res.id
                });
            }
            return results;
        }
    }


    /**
     * Get all results
     */
    async getAllResults() {
        const results = [];
        const collectionRef = this.ngFirestore.collection('results');
        const querySnap = await collectionRef.ref.get();
        if (querySnap.empty) {
            return null;
        } else {
            for (const res of querySnap.docs) {
                results.push(res.data());
            }
            return results;
        }
    }


    /**
     * Uses the internal diagnostic code to grab the full name
     * @param {string} shortName The internal code for the diagnostic
     * @returns {string} The full diagnostic name
     */
    getDiagnosticName(shortName: string) {
        return this.diagnostics[shortName];
    }



    ////////////////////////////////////////////////////////////////
    //////////////////////     BUGS API    /////////////////////////
    ////////////////////////////////////////////////////////////////


    /**
     * Saves a bug report in the Bugs collection in Firebase Firestore
     * @param description Description of the bug
     * @param reproduce The steps required to reproduce the bug
     */
    async submitBugReport(description: string, reproduce: string) {
        const collectionRef = this.ngFirestore.collection('bugs');
        const userEmail = this.authService.getUserEmail();
        const userDetails = (await this.getUserDetails()).data();

        const bugData = {
            description: description,
            reproduce: reproduce,
            date: firestore.FieldValue.serverTimestamp(),
            email: userEmail,
            id: this.authService.getUserId(),
            firstName: userDetails.firstName
        };
        return collectionRef.add(bugData);
    }



    /**
     * Retreives all the bug reports saved in the Bugs collection in Firebase
     * @returns the list of data from the Bugs collection in Firebase Firestore
     */
    async getBugs() {
        const bugs = [];
        const collectionRef = this.ngFirestore.collection('bugs');
        const querySnap = await collectionRef.ref.get();
        if (querySnap.empty) {
            return null;
        } else {
            for (const res of querySnap.docs) {
                bugs.push({
                    data: res.data(),
                    id: res.id
                });
            }
            return bugs;
        }
    }


    /**
     * Marks a bug as resolved
     * @param {string} id The bug ID
     * @returns {Promise<void>}
     */
    async resolveBug(id: string): Promise<void> {
        return this.ngFirestore.collection('bugs').doc(id).update({
            resolved: true
        });
    }




    ////////////////////////////////////////////////////////////////
    //////////////////////     RESOURCES    ////////////////////////
    ////////////////////////////////////////////////////////////////
    
    /**
     * Gets everything from the database
     * @returns 
     */
    async getResources(spanish?: boolean) {
        const allResources = [];
        let resources: any[];
        const collectionRef = this.ngFirestore.collection(spanish ? 'resources-spanish' : 'resources');
        const querySnap = await collectionRef.ref.get();
        if (querySnap.empty) {
            return null;
        } else {
            for (const res of querySnap.docs) {
                allResources.push({
                    data: res.data(),
                    firebaseID: res.id
                });
            }
            resources = allResources;
            return resources;
        }
    }

    async getSummerResources() {
        const allResources = [];
        let resources: any[];
        const collectionRef = this.ngFirestore.collection('resources');
        const querySnap = await collectionRef.ref.where("summer", "==", true).get();
        if (querySnap.empty) {
            return null;
        } else {
            for (const res of querySnap.docs) {
                allResources.push({
                    data: res.data(),
                    firebaseID: res.id
                });
            }
            resources = allResources;
            return resources;
        }
    }


    /**
     * Filters the resource that is grabbed
     * @param searchType 
     * @returns 
     */
    async getResourceByFilter(searchType: string) {
        const allResources = [];
        let resources: any[];
        const collectionRef = this.ngFirestore.collection('resources');
        const querySnap = await collectionRef.ref.where("type", "==", searchType).get();
        if(querySnap.empty) {
            return null;
        } else {
            for (const res of querySnap.docs) {
                allResources.push ({
                    data: res.data(),
                    firebaseID: res.id
                });
            }        
        }
        resources = allResources;
        return resources;
    }


    /**
     * Adds a resource
     * @param {any} resourceObject The resource data
     * @returns {Promise}
     */
    addResource(resourceObject: any) {
        if (resourceObject.language === 'Spanish') {
            return this.ngFirestore.collection('resources-spanish').add(resourceObject);
        } else {
            return this.ngFirestore.collection('resources').add(resourceObject);
        }
    }


    /**
     * Gets a resource from the firestore
     * @param resourceFirebaseID 
     * @returns 
     */
    async getResource(resourceFirebaseID: string) {
        return this.ngFirestore.collection('resources').doc(resourceFirebaseID).ref.get();
    }


    /**
     * Updates the resource in the firestore
     * @param resourceFirebaseID 
     * @param resourceObject 
     * @returns 
     */
    updateResource(resourceFirebaseID: string, resourceObject: any) {
        if (resourceObject.language === 'Spanish') {
            return this.ngFirestore.doc('resources-spanish/' + resourceFirebaseID).update(resourceObject);
        } else {
            return this.ngFirestore.doc('resources/' + resourceFirebaseID).update(resourceObject);
        }
    }


    /**
     * Delete a resource from fireBase
     * @param resourceFirebaseID the Firebase document ID of the resource to delete
     */
    deleteResource(resourceFirebaseID: string) {
        return this.ngFirestore.doc('resources/' + resourceFirebaseID).delete();
    }


    /**
     * 
     * @param type 
     * @returns 
     */
    async getResourcesByType(type: string) {
        const resources = [];
        const collectionRef = this.ngFirestore.collection('resources');
        const querySnap = await collectionRef.ref.where('type', '==', type).get();
        if (querySnap.empty) {
            return null;
        } else {
            for (const res of querySnap.docs) {
                let object = res.data();
                object['id'] = res.id;
                resources.push(object);
            }
            return resources;
        }
    }
    
}