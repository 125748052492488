import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class DiagnosticService {

    private quizJSON: any;
    private currentSlideObject: any;
    private answers: any[] = [];
    private numberCorrect = 0;
    private studentGrade = 3;

    quizFilenames = {
        'ima-ar': 'IMA-AlgebraicReasoning',
        'ima-dc': 'IMA-DecimalConcepts',
        'ima-fc': 'IMA-FractionConcepts',
        'ima-gm': 'IMA-GeometricMeasurement',
        'ima-mdf': 'IMA-MultiDivFluency',
        'ima-pvc': 'IMA-PlaceValueConcepts',
        'ima-rpc': 'IMA-RatioProportionConcepts'
    };

    diagnostics: any[] = [
        {
            name: 'Algebraic Reasoning',
            queryParam: 'ima-ar',
            detailFile: 'ar',
            grades: [3, 4, 5, 6]
        },
        {
            name: 'Decimal Concepts',
            queryParam: 'ima-dc',
            detailFile: 'dc',
            grades: [4, 5, 6]
        },
        {
            name: 'Fraction Concepts',
            queryParam: 'ima-fc',
            detailFile: 'fc',
            grades: [3, 4, 5, 6]
        },
        {
            name: 'Geometric Measurement',
            queryParam: 'ima-gm',
            detailFile: 'gm',
            grades: [3, 4, 5, 6]
        },
        {
            name: 'Multiplication & Division Fluency',
            queryParam: 'ima-mdf',
            detailFile: 'mdf',
            grades: [3, 4, 5, 6]
        },
        {
            name: 'Place Value Concepts',
            queryParam: 'ima-pvc',
            detailFile: 'pvc',
            grades: [3, 4, 5, 6]
        },
        {
            name: 'Ratio Proportion Concepts',
            queryParam: 'ima-rpc',
            detailFile: 'rpc',
            grades: [3, 4, 5, 6]
        },
    ];

    constructor() { }

    /**
     * Retrieves the JSON object for the requested slide number. Each question has two variants.
     * This will choose one of those question variants at random.
     * @param type (optional) The ID of the quiz. Used if requesting the first slide
     */
    async getSlide(slideIndex: number, type?: string): Promise<any> {
        if (slideIndex === 0) {
            this.clearQuiz();
            const fileName = this.quizFilenames[type];
            const response = await fetch('../../assets/quiz_JSON/' + this.studentGrade + '/' + fileName + '.json');
            this.quizJSON = await response.json();
            this.currentSlideObject = this.quizJSON.slides[slideIndex][this.getRandomInt(2)];
            return this.currentSlideObject;
        } else {
            this.currentSlideObject = this.quizJSON.slides[slideIndex][this.getRandomInt(2)];
            return this.currentSlideObject;
        }
    }


    getDiagnostics() {
        return this.diagnostics;
    }


    async getDiagnosticDetails(filename: string) {
        const response = await fetch('../../assets/quiz_detail_JSON/' + filename + '.json');
        return response.json();
    }


    /**
     * Returns the current quiz category as a string. If no quiz is loaded, returns 'IMA' by default.
     */
    getCategory(): string {
        if (this.quizJSON) {
            return this.quizJSON.category;
        }
        return 'IMA';
    }


    /**
     * Receives the user-selected answer, grades it, and saves it to the array of saved answers.
     * @param answerObject Answer object containing a bool for if answer is correct, an object containing the time elapsed,
     * and the subcategory of the current question.
     */
    saveAnswer(answerObject: any) {
        switch (answerObject.format) {
            case 'textInput':
                let textIsCorrect = false;
                if (this.currentSlideObject.answer === answerObject.answer) {
                    this.numberCorrect++;
                    textIsCorrect = true;
                }
                this.answers.push({
                    questionId: this.currentSlideObject.id,
                    isCorrect: textIsCorrect,
                    timeElapsed: answerObject.time,
                    subcategory: this.currentSlideObject.subcategory
                });
                break;

            case 'multipleChoice':
                if (answerObject.answer) {
                    this.numberCorrect++;
                }
                this.answers.push({
                    questionId: this.currentSlideObject.id,
                    isCorrect: answerObject.answer,
                    timeElapsed: answerObject.time,
                    subcategory: this.currentSlideObject.subcategory
                });
                break;

            case 'checkbox':
                let correctCheck = true;
                for (const option of answerObject.answer) {
                    if (option.correct !== option.checked) {
                        correctCheck = false;
                    }
                }
                if (correctCheck) { this.numberCorrect++; }

                this.answers.push({
                    questionId: this.currentSlideObject.id,
                    isCorrect: correctCheck,
                    timeElapsed: answerObject.time,
                    subcategory: this.currentSlideObject.subcategory
                });
                break;

            case 'trueFalse':
                if (answerObject.answer) {
                    this.numberCorrect++;
                }
                this.answers.push({
                    questionId: this.currentSlideObject.id,
                    isCorrect: answerObject.answer,
                    timeElapsed: answerObject.time,
                    subcategory: this.currentSlideObject.subcategory
                });
                break;

            case 'multipleText':
                let answerIsCorrect = true;
                for (let i = 0; i < answerObject.answer.length; i++) {
                    if (answerObject.answer[i] !== this.currentSlideObject.answer[i]) {
                        answerIsCorrect = false;
                    }
                }
                if (answerIsCorrect) {
                    this.numberCorrect++;
                }
                this.answers.push({
                    questionId: this.currentSlideObject.id,
                    isCorrect: answerIsCorrect,
                    timeElapsed: answerObject.time,
                    subcategory: this.currentSlideObject.subcategory
                });
                break;
            case 'skipped':
                this.answers.push({
                    questionId: this.currentSlideObject.id,
                    isCorrect: false,
                    skipped: true,
                    timeElapsed: answerObject.time,
                    subcategory: this.currentSlideObject.subcategory
                });
                break;
            default:
                break;
        }
    }


    /**
     * Returns the array of answer objects.
     */
    getAnswers(): any[] {
        return this.answers;
    }


    /**
     * Returns the number of questions in the quiz. This does not combine all possible questions,
     * only the number of questions presented to the user taking the quiz.
     */
    getNumberOfQuestions(): number {
        if (this.quizJSON) {
            return this.quizJSON.slides.length;
        } else {
            return 0;
        }
    }


    /**
     * Returns the list of subcategories for the current quiz.
     */
    getSubcategories(): string[] {
        if (this.quizJSON) {
            return this.quizJSON.subcategories;
        } else {
            return ['No Quiz Loaded'];
        }
    }


    /**
     * Returns a random number from 0 to (max - 1).
     * @param max The span from zero to pick from
     */
    private getRandomInt(max): number {
        return Math.floor(Math.random() * Math.floor(max));
    }


    /**
     * Returns the number of correct answers.
     */
    getNumberCorrect(): number {
        return this.numberCorrect;
    }


    /**
     * Resets quiz variables to empty states.
     */
    clearQuiz(): void {
        this.quizJSON = {};
        this.currentSlideObject = {};
        this.answers = [];
        this.numberCorrect = 0;
        this.numberCorrect = 0;
    }


    /**
     * Return the list of diagnostic names and their id's used for query parameters
     */
    getQuizList(): any[] {
        return this.diagnostics;
    }


    /**
     * Set the student's grade so we can check it against diagnostic subcategories
     * @param gradeNumber The grade the student is in
     */
    setStudentGrade(gradeNumber) {
        this.studentGrade = gradeNumber;
    }

}
