import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { AngularFireUploadTask } from '@angular/fire/storage';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as _ from 'underscore';

@Component({
	selector: 'app-edit-resource',
	templateUrl: './edit-resource.component.html',
	styleUrls: ['./edit-resource.component.scss']
})
export class EditResourceComponent implements OnInit {

	resource: any;
	routeState: any;
	firebaseID: string;
	originalNumberOfFiles: number;

	loading: boolean = false;
	deleted: boolean = false;
	submit: boolean = false;
	changesMade: boolean = false;
	fileInputted: boolean = false;
	isSubmitting: boolean;

	admin: boolean = false;

	isHovering: boolean;

	task: AngularFireUploadTask;

	error: string;

	grades: number[];
	languagesList: string[];
	gradeLevels: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

	primaryDimensions = ['Spatial Reasoning', 'Number Facts', 'Number Sequencing', 'Relational Thinking', 'Interpreting Context', 'Measurement'];
	intermediateDimensions = ['Algebraic Reasoning', 'Multiplication & Division Fluency', 'Fraction Concepts', 'Ratio & Proportion Concepts', 'Place Value & Decimal Concepts', 'Geometric Measurement'];

	files: File[] = [];
	fileData: any[] = [];

	constructor(
		private userService: UserService,
		public router: Router,
		private snackBar: MatSnackBar
	) {
		if (this.router.getCurrentNavigation().extras.state) {
			this.routeState = this.router.getCurrentNavigation().extras.state;
			if (this.routeState) {
				this.resource = this.routeState.resource;
				this.firebaseID = this.routeState.firebaseID;
			}
		}
	}

	ngOnInit() {
		this.userService.getUserDetails().then(res => {
			let data = res.data();
			if (!data.admin) {
				this.router.navigate(['resources']);
			} else {
				this.admin = data.admin
			}
		});
		
		if (this.resource.type === 'Module') {
			if (_.isUndefined(this.resource.moduleVideos) || _.isNull(this.resource.moduleVideos) || this.resource.moduleVideos.length === 0) {
				this.resource.moduleVideos = [''];
			}
			if (_.isUndefined(this.resource.google) || _.isNull(this.resource.google)) {
				this.resource['google'] = '';
			}
		}
		if (this.resource.type === 'Targeted') {
			if (_.isUndefined(this.resource.dimension) || _.isNull(this.resource.dimension)) {
				this.resource['dimension'] = '';
			}
		}
		if (_.isNull(this.resource.summer) || _.isUndefined(this.resource.summer)) {
			this.resource['summer'] = false;
		}
		if (_.isUndefined(this.resource.files) || _.isNull(this.resource.files)) {
			this.resource.files = [];
		}else {
			this.originalNumberOfFiles = this.resource.files.length;
		}
	}

	/**
	 * If the resource type is a video, it will begin the firestore upload
	 * Else, it will take data from the upload-task through onUploadFinished()
	 */
	clickedSubmit() {
		if (_.isEmpty(this.files)) {
			this.updateResource();
		}
		else {
			this.isSubmitting = true;
		}
	}

	/**
	 * Uses data from the file upload to fill out the JSON file's fields
	 * @param event data passed to add-resource from upload-task
	 */
	onUploadFinished(event: any) {
		this.fileData.push({ path: event.path, url: event.fileUrl });
        if (this.files.length == this.fileData.length) {
			let mergedFileArray = [...this.resource.files, ...this.fileData];
            this.updateResource(mergedFileArray);
        }
	}

	/**
	 * Handles hovering over the dropbox
	 * @param event 
	 */
	toggleHover(event: boolean) {
		this.isHovering = event;
	}

	/**
     * Handles dropping files onto the dropbox
     * @param files
     */
	 onDrop(files: FileList) {
		this.changesMade = true;
        for (let i = 0; i < files.length; i++) {
            this.files.push(files.item(i));
        }
        this.fileInputted = true;
    }

	/**
	 * Updates the resource in Firebase
	 */
	updateResource(files?: any[]) {
		if (files) {
			this.resource.files = files;
		}
		this.resource.dateUpdated = new Date();

		if (this.resource.type === 'Module') {
			if (this.resource.moduleVideos && this.resource.moduleVideos.length > 0) {
				for (let i=0; i < this.resource.moduleVideos.length; i++) {
					let url = this.resource.moduleVideos[i];
					if (url === '') {
						this.resource.moduleVideos.splice(i, 1);
					}
				}
			}
			
            if (this.resource.google === '') {
                this.resource.google = null;
            }
		}
		if (this.resource.type === 'Targeted') {
			if (this.resource.dimension === '') {
				this.resource.dimension = null;
			}
		}
		if (_.isUndefined(this.resource.files) || _.isNull(this.resource.files) || this.resource.files.length == 0) {
			this.resource.files = null;
		}
		this.userService.updateResource(this.firebaseID, _.omit(this.resource, 'id')).then(() => {
			this.router.navigate(['/resources']);
		}).catch(err => {
			console.error(err);
			alert('Something went wrong. Please refresh and try again.');
		});
	}

	/**
	 * Adds another video link to the moduleVideos array as an empty string.
	 * This generates another form field in the template
	 */
	addAnotherVideo() {
		this.resource.moduleVideos.push('');
	}

	/**
	 * Removes the video from the moduleVideos list
	 * @param index The index of the class to remove from the list
	 */
	removeVideo(index: number) {
		this.resource.moduleVideos.splice(index, 1);
	}


	/**
		 * Adds another topic to the topics array as an empty string.
		 * This generates another form field in the template
		 */
	addAnotherTopic() {
		this.resource.topic.push('');
	}

	/**
	 * Removes the topic from the topics list
	 * @param index The index of the class to remove from the list
	 */
	removeTopic(index: number) {
		this.resource.topic.splice(index, 1);
	}

	/**
	 * Helper function for an *ngFor in the tempate.
	 * @param index 
	 * @param obj 
	 * @returns 
	 */
	trackByIndex(index: number, obj: any): any {
		return index;
	}

	getFilename(path: string) {
		return path.substring(29);
	}

	removeFile(index: number) {
		this.changesMade = true;
		this.resource.files.splice(index, 1);
	}


	isPrimary() {
		return (this.resource.grades.includes(0) || this.resource.grades.includes(1) || this.resource.grades.includes(2));
	}


	/**
   * Pops up a toast message using Angular Material's 'SnackBar' Component.
   * @param message The message to display in the toast (snackBar)
   * @param action Optional action button text
   */
	popToast(message: string, action: string, style: string) {
		this.snackBar.open(message, action, {
			duration: 3000,
			panelClass: style
		});
	}

	deleteResource() {
		this.loading = true;
		this.userService.deleteResource(this.firebaseID).then(() => {
			this.deleted = true;
		}).catch(err => {
			console.error(err);
			alert('Something went wrong. Please refresh and try again.');
		});
	}
}