import { Component, OnInit, Input } from '@angular/core';
import { firestore } from 'firebase';
import { mean, std, min, max } from 'mathjs';
import * as _ from 'underscore';
import * as Lodash from 'lodash';

interface Result {
    answers: any[];
    category: string;
    grade: number;
    date: firestore.Timestamp;
    finalTime: string;
    numberCorrect: number;
    numberOfQuestions: number;
    percentCorrect: number;
    student: firestore.DocumentReference;
    subcategories: string[];
    subcategoryScores: any;
    teacher: firestore.DocumentReference;
}

@Component({
    selector: 'app-reports-admin',
    templateUrl: './reports-admin.component.html',
    styleUrls: ['./reports-admin.component.scss']
})

export class ReportsAdminComponent implements OnInit {

    @Input() schoolData: any;

    teachers: string[];
    grades = [3, 4, 5, 6];

    selectedTeacher: string;
    selectedGrade = 3;

    winterResults: any;
    winterBoxPlot: any;

    springResults: any;
    springBoxPlot: any;

    teacherBoxPlots = {};
    teacherResults: any;

    gradeResults = {};
    gradeBoxPlots = {};

    totalBoxPlots = {};

    loading = true;
    changingTeacher = false;
    changingGrade = false;

    dimensions = [
        'Algebraic Reasoning',
        'Decimal Concepts',
        'Fraction Concepts',
        'Geometric Measurement',
        'Multiplication & Division Fluency',
        'Place Value Concepts',
        'Ratio Proportion Concepts'
    ];

    fallResults = {
        'Algebraic Reasoning': [],
        'Decimal Concepts': [],
        'Fraction Concepts': [],
        'Geometric Measurement': [],
        'Multiplication & Division Fluency': [],
        'Place Value Concepts': [],
        'Ratio Proportion Concepts': []
    };
    fallBoxPlot: any;

    constructor() { }

    ngOnInit() {
        this.teachers = _.keys(this.schoolData).sort();
        this.selectedTeacher = this.teachers[0];

        this.createDataObjects();
        this.sortSemesters();
        this.calculateTotalAverages();
        this.calculateOtherAverages(this.teachers, this.teacherResults, this.teacherBoxPlots);
        this.calculateOtherAverages(this.grades, this.gradeResults, this.gradeBoxPlots);

        this.loading = false;
    }


    createDataObjects() {
        this.teacherResults = this.teachers.reduce( (current, item) => {
            current[item] = {
                fall: {
                    'Algebraic Reasoning': [],
                    'Decimal Concepts': [],
                    'Fraction Concepts': [],
                    'Geometric Measurement': [],
                    'Multiplication & Division Fluency': [],
                    'Place Value Concepts': [],
                    'Ratio Proportion Concepts': []
                },
                winter: {
                    'Algebraic Reasoning': [],
                    'Decimal Concepts': [],
                    'Fraction Concepts': [],
                    'Geometric Measurement': [],
                    'Multiplication & Division Fluency': [],
                    'Place Value Concepts': [],
                    'Ratio Proportion Concepts': []
                },
                spring: {
                    'Algebraic Reasoning': [],
                    'Decimal Concepts': [],
                    'Fraction Concepts': [],
                    'Geometric Measurement': [],
                    'Multiplication & Division Fluency': [],
                    'Place Value Concepts': [],
                    'Ratio Proportion Concepts': []
                }
            };
            return current;
        }, {});

        for (const grade of this.grades) {
            this.gradeResults[grade] = {
                fall: {
                    'Algebraic Reasoning': [],
                    'Decimal Concepts': [],
                    'Fraction Concepts': [],
                    'Geometric Measurement': [],
                    'Multiplication & Division Fluency': [],
                    'Place Value Concepts': [],
                    'Ratio Proportion Concepts': []
                },
                winter: {
                    'Algebraic Reasoning': [],
                    'Decimal Concepts': [],
                    'Fraction Concepts': [],
                    'Geometric Measurement': [],
                    'Multiplication & Division Fluency': [],
                    'Place Value Concepts': [],
                    'Ratio Proportion Concepts': []
                },
                spring: {
                    'Algebraic Reasoning': [],
                    'Decimal Concepts': [],
                    'Fraction Concepts': [],
                    'Geometric Measurement': [],
                    'Multiplication & Division Fluency': [],
                    'Place Value Concepts': [],
                    'Ratio Proportion Concepts': []
                }
            };
        }
        this.fallBoxPlot = new Array(7);
        this.winterBoxPlot = new Array(7);
        this.springBoxPlot = new Array(7);

        this.winterResults = Lodash.cloneDeep(this.fallResults);
        this.springResults = Lodash.cloneDeep(this.fallResults);
    }


    /**
     * Sort all results by date into Fall, Winter, Spring
     */
    sortSemesters() {
        for (const key of _.keys(this.schoolData)) {
            const results = this.schoolData[key] as Result[];
            for (const result of results) {
                if (result.grade) {
                    const month = result.date.toDate().getMonth();
                    if (month <= 11 && month >= 8) {
                        this.fallResults[result.category].push(result.percentCorrect);
                        this.teacherResults[key].fall[result.category].push(result.percentCorrect);
                        this.gradeResults[result.grade].fall[result.category].push(result.percentCorrect);
                    } else if (month <= 2 && month >= 0) {
                        this.winterResults[result.category].push(result.percentCorrect);
                        this.teacherResults[key].winter[result.category].push(result.percentCorrect);
                        this.gradeResults[result.grade].winter[result.category].push(result.percentCorrect);
                    } else if (month <= 5 && month >= 3) {
                        this.springResults[result.category].push(result.percentCorrect);
                        this.teacherResults[key].spring[result.category].push(result.percentCorrect);
                        this.gradeResults[result.grade].spring[result.category].push(result.percentCorrect);
                    }
                } else {
                    console.log('found a result without a grade: ', result);
                }
            }
        }
    }


    /**
     * Create the boxplot data across all teachers and prepares it for Highcharts
     */
    calculateTotalAverages() {
        for (let i = 0; i < this.dimensions.length; i++) {
            const dim = this.dimensions[i];
            if (this.fallResults[dim] && this.fallResults[dim].length > 0) {
                const sortedResults = this.fallResults[dim].sort((a: number, b: number) => a - b);
                this.fallBoxPlot[i] = this.createBoxPlot(sortedResults, dim, 'rgb(237, 157, 90)', i);
            } else {
                this.fallBoxPlot[i] = {x: i, low: 0, q1: 0, median: 0, q3: 0, high: 0, name: dim + ': No Data', color: 'rgb(237, 157, 90)'};
            }
            if (this.winterResults[dim] && this.winterResults[dim].length > 0) {
                const sortedResults = this.winterResults[dim].sort((a: number, b: number) => a - b);
                this.winterBoxPlot[i] = this.createBoxPlot(sortedResults, dim, 'rgb(3, 105, 227)', i);
            } else {
                this.winterBoxPlot[i] = {x: i, low: 0, q1: 0, median: 0, q3: 0, high: 0, name: dim + ': No Data', color: 'rgb(3, 105, 227)'};
            }
            if (this.springResults[dim] && this.springResults[dim].length > 0) {
                const sortedResults = this.springResults[dim].sort((a: number, b: number) =>  a - b);
                this.springBoxPlot[i] = this.createBoxPlot(sortedResults, dim, 'rgb(163, 241, 146)', i);
            } else {
                this.springBoxPlot[i] = {x: i, low: 0, q1: 0, median: 0, q3: 0, high: 0, name: dim + ': No Data', color: 'rgb(163, 241, 146)'};
            }
        }
        this.totalBoxPlots = {
            fall: this.fallBoxPlot,
            winter: this.winterBoxPlot,
            spring: this.springBoxPlot
        };
    }


    /**
     * Takes results and calculates the boxplot objects for Highcharts and sorts them into the object passed as 'boxplots' param
     * @param keys An array of strings or numbers to iterate over that will be used as keys in the boxplot objects
     * @param results An object of results that has been sorted into semesters
     * @param boxplots The destination object for these calculations
     */
    calculateOtherAverages(keys: any[], results: any, boxplots: any) {
        for (const item of keys) {
            boxplots[item] = {
                fall: new Array(6),
                winter: new Array(6),
                spring: new Array(6)
            };
            for (let i = 0; i < this.dimensions.length; i++) {
                const dim = this.dimensions[i];

                // FALL
                const fallResults = results[item].fall[dim] as number[];
                if (fallResults && fallResults.length > 0) {
                    const sortedFallResults = fallResults.sort((a: number, b: number) => a - b);
                    boxplots[item].fall[i] = this.createBoxPlot(sortedFallResults, dim, 'rgb(237, 157, 90)', i);
                } else {
                    boxplots[item].fall[i] = {x: i, low: 0, q1: 0, median: 0, q3: 0, high: 0, name: dim + ': No Data', color: 'rgb(237, 157, 90)'};
                }

                // WINTER
                const winterResults = results[item].winter[dim] as number[];
                if (winterResults && winterResults.length > 0) {
                    const sortedWinterResults = winterResults.sort((a: number, b: number) => a - b);
                    boxplots[item].winter[i] = this.createBoxPlot(sortedWinterResults, dim, 'rgb(3, 105, 227)', i);
                } else {
                    boxplots[item].winter[i] = {x: i, low: 0, q1: 0, median: 0, q3: 0, high: 0, name: dim + ': No Data', color: 'rgb(3, 105, 227)'};
                }

                // SPRING
                const springResults = results[item].spring[dim] as number[];
                if (springResults && springResults.length > 0) {
                    const sortedSpringResults = springResults.sort((a: number, b: number) => a - b);
                    boxplots[item].spring[i] = this.createBoxPlot(sortedSpringResults, dim, 'rgb(163, 241, 146)', i);
                } else {
                    boxplots[item].spring[i] = {x: i, low: 0, q1: 0, median: 0, q3: 0, high: 0, name: dim + ': No Data', color: 'rgb(163, 241, 146)'};
                }
            }
        }
    }


    /**
     * Construct the boxplot data arrays required for the Highcharts graphs
     * @param array The array of results
     * @param name Name to be displayed in Highcharts graph
     * @param color Color to be used for the boxplot
     * @param index The index that corresponds to the X-axis dimension
     * @returns The data object for Highcharts
     */
    createBoxPlot(array: number[], name: string, color: string, index: number) {
        const q1 = this.quantile(array, .25);
        const q3 = this.quantile(array, .75);
        const median = this.quantile(array, .50);
        const minimum = min(array);
        const maximum = max(array);

        return {
            x: index,
            low: minimum,
            q1,
            median,
            q3,
            high: maximum,
            name,
            color
        };
    }


    /**
     * Calculates the quantile from a given list of numbers
     * @param array The array of results
     * @param q The degree of quantile to calculate
     * @returns The calculated Quantile
     */
    quantile(array: number[], q) {
        const pos = (array.length - 1) * q;
        const base = Math.floor(pos);
        const rest = pos - base;
        if (array[base + 1] !== undefined) {
            return array[base] + rest * (array[base + 1] - array[base]);
        } else {
            return array[base];
        }
    }


    onUpdateGraph(type: string) {
        if (type === 'teacher') {
            this.changingTeacher = true;
            setTimeout(() => {
                this.changingTeacher = false;
            }, 200);
        } else if (type === 'grade') {
            this.changingGrade = true;
            setTimeout(() => {
                this.changingGrade = false;
            }, 200);
        }
    }
}
