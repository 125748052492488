import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { DiagnosticService } from '../services/diagnostic.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as _ from 'underscore';


@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

    constructor(
        private router: Router,
        private userService: UserService,
        private diagService: DiagnosticService,
        private snackBar: MatSnackBar
    ) { }

    userDetails: any;
    students: any[];
    diagnostics: any[];
    eligibleDiagnostics: any[];
    studentsToEnroll: any[];

    loading = false;
    readyToEnroll = false;
    generatingDiagnostic = false;
    showAdmin = false;
    showBugs = false;
    removingDiagnostics = false;
    homeschool: boolean;

    error: string;
    selectedDiagnostic: any;
    selectedStudent: any;
    studentToDelete: any;

    ngOnInit() {

        this.loading = true;
        this.diagnostics = this.diagService.getQuizList();

        // Load user and students' details
        this.userDetails = this.userService.getUserDetails().then(teacherResponse => {
            this.userDetails = teacherResponse.data();
            this.homeschool = this.userDetails.homeschool;
            this.showAdmin = this.userDetails.admin;
            this.showBugs = this.userDetails.developer;
            this.userService.getStudents().then(studentResponse => {
                this.students = _.sortBy(studentResponse, (student) => {
                    return student.data.lastName;
                });
                this.loading = false;
            }).catch(err => {
                console.error(err);
                this.popToast('An error occurred. Try refreshing the page.', '', 'toast-error');
            });

            // Debug function to find results without the subcategoryScores property
            // if (this.showBugs) {
            //     this.userService.getAllResults().then(res => {
            //         let filteredResults = _.reject(res, (result) => {
            //             return _.has(result, 'subcategoryScores');
            //         });
            //         console.log('filteredResults: ', filteredResults);
            //     });
            // }
        }).catch(err => {
            this.loading = false;
            console.error(err);
            this.popToast('An error occurred. Try refreshing the page.', '', 'toast-error');
        });
    }


    /**
     * Calls the user service to add the 'deleted: true' property to the student in Firebase.
     * Then removes the student from the array being rendered in the UI.
     */
    deleteStudent() {
        this.userService.deleteStudent(this.studentToDelete.firebaseID).then(res => {
            this.students = _.reject(this.students, (student: any) => {
                return student.firebaseID === this.studentToDelete.firebaseID;
            });
            this.popToast('Student was successfully deleted.', '', 'toast-success');
        }).catch(err => {
            console.error(err);
        });
    }

    /**
     * Loop through all students and add them to an active diagnostic window.
     */
    onEnrollStudents() {
        this.loading = true;

        this.userService.addClassCodes(this.studentsToEnroll, this.selectedDiagnostic).then(studentsRes => {
            for (let i = 0; i < this.students.length; i++) {
                let codes = studentsRes[i].data.activeCodes;
                this.students[i].data.activeCodes = codes;
            }
            // this.findActiveStudents();
            this.loading = false;
        }).catch(err => {
            console.error(err);
            this.loading = false;
        });
    }




    /**
     * Click handler for selecting the diagnostic to enroll the student in.
     * @param diagnostic any - The selected diagnostic object
     */
    onSelectDiagnostic(diagnostic: any) {
        this.selectedDiagnostic = diagnostic;
        this.readyToEnroll = true;
    }


    /**
     * Go to the diagnostic detail page
     * @param diagnosticObj The selected diagnostic 
     */
    goToDiagnosticDetail(diagnosticObj: any) {
        this.router.navigate(['diagnostics', diagnosticObj.detailFile]);
    }



    /**
     * Click handler for clicking the 'diagnostic' button in the student list
     * @param student The student object from Firebase
     */
    selectStudentToEnroll(student: any) {
        this.selectedStudent = student;
        this.eligibleDiagnostics = _.reject(this.diagnostics, (diagnostic: any) => {
            return _.indexOf(diagnostic.grades, student.data.grade) === -1;
        });
    }


    /**
     * Click handler for clicking the 'delete' button in the student list
     * @param student The student object from Firebase
     */
    selectStudentToDelete(student: any) {
        this.studentToDelete = student;
    }



    /**
     * 
     * @param studentID string - The ID of the student
     * @param grade number - The student's grade
     */
    enrollStudent() {
        this.generatingDiagnostic = true;
        const diagnostic = this.selectedDiagnostic;
        this.userService.addCode(this.selectedStudent, diagnostic).then(studentRes => {
            this.selectedStudent = studentRes;
            this.generatingDiagnostic = false;
            this.popToast(this.selectedStudent.data.firstName + ' was successfully enrolled in the Diagnostic.', '', 'toast-success');
        }).catch(err => {
            this.generatingDiagnostic = false;
            console.error(err);
            this.popToast('Something went wrong. Student was not enrolled. Please refresh and try again.', '', 'toast-error');
        });
    }

    /**
   * Use the UserService to remove the active diagnostic code from the activeDiagnostics collection
   * and remove it from the student's activeDiagnostic field.
   * @param code string - The active diagnostic code to be removed
   * @param student any - The student object used to update the front end
   */
    onRemoveCode(student: any, codeObject: any) {
        this.removingDiagnostics = true;

        this.userService.removeCode(student, codeObject).then(() => {
            student.data.activeCodes = _.reject(student.data.activeCodes, (codeObj: any) => {
                return _.isEqual(codeObj, codeObject);
            });
            this.removingDiagnostics = false;
        }).catch(err => {
            console.error(err);
            this.removingDiagnostics = false;
            this.popToast('An error occurred: ' + err, '', 'toast-error');
        });
    }

    printCodes() {
        window.print();
    }

    /**
     * Pops up a toast message using Angular Material's 'SnackBar' Component.
     * @param message The message to display in the toast (snackBar)
     * @param action Optional action button text
     */
    popToast(message: string, action: string, style: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
            panelClass: style
        });
    }

    /**
 * Sets values back to default.
 */
    resetSelectedDiagnostic() {
        this.selectedDiagnostic = {};
        this.readyToEnroll = false;
    }
}
