import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DiagnosticService } from '../../services/diagnostic.service';

@Component({
    selector: 'app-diagnostic-detail',
    templateUrl: './diagnostic-detail.component.html',
    styleUrls: ['./diagnostic-detail.component.scss']
})
export class DiagnosticDetailComponent implements OnInit {

    diagnostic: any;
    loading = true;

    constructor(private activatedRoute: ActivatedRoute, private diagService: DiagnosticService) { }

    ngOnInit() {
        const filename = this.activatedRoute.snapshot.paramMap.get('diagnostic');

        this.diagService.getDiagnosticDetails(filename).then(res => {
            this.diagnostic = res;
            this.loading = false;
        }).catch(err => {
            console.error(err);
        });
    }

}
