import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { environment } from '../environments/environment';
import {
    MatSliderModule,
    MatCardModule,
    MatGridListModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatRadioModule,
    MatListModule,
    MatIconModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatChipsModule,
    MatCheckboxModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatTableModule,
    MatSnackBarModule,
    MatSortModule,
    MatTabsModule,
    MatExpansionModule
} from '@angular/material';
import { MatMenuModule } from '@angular/material/menu';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { MatTableExporterModule } from 'mat-table-exporter';
import { HighchartsChartModule } from 'highcharts-angular';
import { MarkdownModule } from 'ngx-markdown';
import { NavigationComponent } from './navigation/navigation.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DiagnosticComponent } from './diagnostics/diagnostic/diagnostic.component';
import { ResultsDetailsComponent } from './results/results-details/results-details.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { LayoutModule } from '@angular/cdk/layout';
import { DiagnosticListComponent } from './diagnostics/diagnostic-list/diagnostic-list.component';
import { SignupComponent } from './signup/signup.component';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthService } from './services/auth.service';
import { DiagnosticSlideComponent } from './diagnostics/diagnostic-slide/diagnostic-slide.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { StudentsPageComponent } from './student/students-page/students-page.component';
import { StudentListComponent } from './student/student-list/student-list.component';
import { CreateStudentPageComponent } from './student/create-student-page/create-student-page.component';
import { StudentDetailsPageComponent } from './student/student-details-page/student-details-page.component';
import { ResultsDetailsPageComponent } from './results/results-details-page/results-details-page.component';
import { ResultsListComponent } from './results/results-list/results-list.component';
import { DiagnosticListPageComponent } from './diagnostics/diagnostic-list-page/diagnostic-list-page.component';
import { EditStudentPageComponent } from './student/edit-student-page/edit-student-page.component';
import { StudentDialogComponent } from './student/student-dialog/student-dialog.component';
import { DiagnosticLoginComponent } from './diagnostics/diagnostic-login/diagnostic-login.component';
import { ClassListComponent } from './class/class-list/class-list.component';
import { ClassDetailComponent } from './class/class-detail/class-detail.component';
import { ModalComponent } from './modal/modal.component';
import { ReportsComponent } from './reports/reports.component';
import { ReportsListComponent } from './reports/reports-list/reports-list.component';
import { ReportsDetailComponent } from './reports/reports-detail/reports-detail.component';
import { CreateClassComponent } from './class/create-class/create-class.component';
import { CreateClassPageComponent } from './class/create-class-page/create-class-page.component';
import { DiagnosticDetailComponent } from './diagnostics/diagnostic-detail/diagnostic-detail.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { DiagnosticFinishComponent } from './diagnostics/diagnostic-finish/diagnostic-finish.component';
import { TutorialPageComponent } from './tutorial-page/tutorial-page.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { AdminComponent } from './admin/admin.component';
import { BugReportPageComponent } from './bug/bug-report-page/bug-report-page.component';
import { BugReportComponent } from './bug/bug-report/bug-report.component';
import { BugViewComponent } from './bug/bug-view/bug-view.component';
import { KatexModule } from 'ng-katex';
import { EditTeacherComponent } from './teacher/edit-teacher/edit-teacher.component';
import { AddResourceComponent } from './resource/add-resource/add-resource.component';
import { DropzoneDirective } from './dropzone.directive';
import { UploadTaskComponent } from './resource/add-resource/upload-task/upload-task.component';
import { AngularFireStorage } from '@angular/fire/storage';
import { SupplementalListComponent } from './resource/supplemental-list/supplemental-list.component';
import { EditResourceComponent } from './resource/edit-resource/edit-resource.component';
import { ResourceComponent } from './resource/resource.component';
import { ResourceDetailComponent } from './resource/resource-detail/resource-detail.component';
import { ResourcePageComponent } from './resource/resource-page/resource-page.component';
import { ResourceModuleComponent } from './resource/resource-module/resource-module.component';
import { ResourceTargetedComponent } from './resource/resource-targeted/resource-targeted.component';
import { ActiveCodesComponent } from './active-codes/active-codes.component';
import { FilenameSortPipe } from './pipes/filename-sort.pipe';
import { ReportsAdminComponent } from './reports/reports-admin/reports-admin.component';
import { SupplementalPageComponent } from './resource/supplemental-page/supplemental-page.component';
import { VideoTutorialPageComponent } from './resource/video-tutorial-page/video-tutorial-page.component';
import { VideoTutorialListComponent } from './resource/video-tutorial-list/video-tutorial-list.component';
import { ReportsAdminGraphComponent } from './reports/reports-admin-graph/reports-admin-graph.component';
import { SummerResourcePageComponent } from './resource/summer-resource-page/summer-resource-page.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NavigationComponent,
    DiagnosticComponent,
    ResultsDetailsComponent,
    PageNotFoundComponent,
    DiagnosticListComponent,
    SignupComponent,
    DiagnosticSlideComponent,
    DashboardComponent,
    StudentsPageComponent,
    StudentListComponent,
    CreateStudentPageComponent,
    StudentDetailsPageComponent,
    ResultsDetailsPageComponent,
    ResultsListComponent,
    StudentDialogComponent,
    DiagnosticListPageComponent,
    EditStudentPageComponent,
    DiagnosticLoginComponent,
    ClassListComponent,
    ClassDetailComponent,
    ModalComponent,
    ReportsComponent,
    ReportsListComponent,
    ReportsDetailComponent,
    CreateClassComponent,
    CreateClassPageComponent,
    DiagnosticDetailComponent,
    ResetPasswordComponent,
    DiagnosticFinishComponent,
    TutorialPageComponent,
    ScheduleComponent,
    AdminComponent,
    BugReportPageComponent,
    BugReportComponent,
    BugViewComponent,
    EditTeacherComponent,
    AddResourceComponent,
    DropzoneDirective,
    UploadTaskComponent,
    SupplementalListComponent,
    EditResourceComponent,
    ResourceComponent,
    ResourceDetailComponent,
    ResourcePageComponent,
    ResourceModuleComponent,
    ResourceTargetedComponent,
    ActiveCodesComponent,
    FilenameSortPipe,
    ReportsAdminComponent,
    SupplementalPageComponent,
    VideoTutorialPageComponent,
    VideoTutorialListComponent,
    ReportsAdminGraphComponent,
    SummerResourcePageComponent
  ],
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    MatSliderModule,
    MatCardModule,
    MatGridListModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatButtonToggleModule,
    LayoutModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatRadioModule,
    MatChipsModule,
    MatCheckboxModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatTableModule,
    MatTabsModule,
    MatSnackBarModule,
    MatSortModule,
    MatExpansionModule,
    MarkdownModule.forRoot(),
    HighchartsChartModule,
    MatTableExporterModule,
    KatexModule,
    SlickCarouselModule,
    MatMenuModule
  ],
  entryComponents: [
    StudentDialogComponent
  ],
  providers: [
      AuthGuardService,
      AuthService,
      AngularFireStorage,
      FilenameSortPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
