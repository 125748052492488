import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-edit-student-page',
    templateUrl: './edit-student-page.component.html',
    styleUrls: ['./edit-student-page.component.scss']
})
export class EditStudentPageComponent implements OnInit {

    student: any;
    studentFirebaseID: string;
    loading = true;

    firstName: string;
    lastName: string;
    studentID: string;
    grade: string;

    error: string;
    selectedClass: string;

    classes: any[];

    constructor(private userService: UserService, private activatedRoute: ActivatedRoute, private router: Router) { }

    ngOnInit() {
        this.studentFirebaseID = this.activatedRoute.snapshot.paramMap.get('id');

        this.userService.getUserDetails().then(res => {
            this.classes = res.data().classes;

            this.userService.getStudentByDocument(this.studentFirebaseID).then(res => {
                this.student = res.data();
                this.firstName = this.student.firstName;
                this.lastName = this.student.lastName;
                this.studentID = this.student.studentID;
                this.grade = this.student.grade;
                this.selectedClass = this.student.class;
                this.loading = false;
            }).catch(err => {
                this.error = 'Something went wrong when trying to load the student\'s data. Please refresh and try again.';
                this.loading = false;
                console.error(err);
            });
        });

        
    }

    updateStudent() {
        this.error = '';
        if ((!this.firstName || !this.lastName || !this.studentID || !this.grade) || this.firstName === '' || this.lastName === '' || this.studentID === '') {
            this.error = 'All form fields are required. Check the form and try again.';
        } else {
            this.loading = true;
            this.student = {
                firstName: this.firstName,
                lastName: this.lastName,
                grade: Number(this.grade),
                studentID: this.studentID,
                class: this.selectedClass
            }
            this.userService.updateStudent(this.studentFirebaseID, this.student).then(() => {
                this.loading = false;
                this.router.navigate(['/ima-dashboard']);
            }).catch(err => {
                this.loading = false;
                console.error(err);
            });
        }
    }

}
