import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { AngularFireUploadTask } from '@angular/fire/storage';
import { MatSnackBar } from '@angular/material/snack-bar';
import { throwToolbarMixedModesError } from '@angular/material';

@Component({
    selector: 'app-add-resource',
    templateUrl: './add-resource.component.html',
    styleUrls: ['./add-resource.component.scss']
})
export class AddResourceComponent implements OnInit {

    loading: boolean = false;
    submit: boolean = false;
    fileInputted: boolean = false;
    showUnit: boolean;
    isSubmitting: boolean = false;
    isHovering: boolean;
    isSummerSchool = false;

    task: AngularFireUploadTask;

    title: string;
    description: string;
    dimension: string;
    type: string;
    selectedGrade: number[];
    selectedLanguage: string;
    tutorialVideo: string;
    googleUrl: string;
    intro: string;
    demo: string;
    url: string;
    path: string;
    error: string;
    supplementalTopic: string;

    gradeList: string[];
    languagesList: string[];
    moduleVideos: string[] = [''];
    topics: string[] = [''];
    gradeLevels: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

    files: File[] = [];
    fileData: any[] = [];

    primaryDimensions = ['Spatial Reasoning', 'Number Facts', 'Number Sequencing', 'Relational Thinking', 'Interpreting Context', 'Measurement']
	intermediateDimensions = ['Algebraic Reasoning', 'Multiplication & Division Fluency', 'Fraction Concepts', 'Ratio & Proportion Concepts', 'Place Value & Decimal Concepts', 'Geometric Measurement'];

    supplementalTopics = ['DMTI Overviews', 'Fluency & Flexibility', 'Measurement', 'Data', 'Geometry', 'Number Primary', 'Number Intermediate', 'Number Middle School', 'Algebraic Reasoning'];

    unitNumber: number;

    constructor(
        private userService: UserService,
        public router: Router,
        private snackBar: MatSnackBar
    ) { }

    ngOnInit() {
    }


    /**
     * Uses data from the file upload to fill out the JSON file's fields
     * @param event data passed to add-resource from upload-task
     */
    onUploadFinished(event: any) {
        this.fileData.push({ path: event.path, url: event.fileUrl });
        if (this.files.length == this.fileData.length) {
            this.createResource();
        }
    }


    /**
     * If the resource type is a video, it will begin the firestore upload
     * Else, it will take data from the upload-task through onUploadFinished()
     */
    clickedSubmit() {
        if (
            this.title &&
            this.description &&
            this.type &&
            this.selectedGrade &&
            this.topics.length > 0 &&
            this.selectedLanguage &&
            this.gradeLevels
        ) {
            if (this.fileInputted) {
                this.isSubmitting = true;
            } else {
                this.createResource();
            }
            
        }
    }


    /**
     * Uploads the JSON file to firestore
     */
    createResource() {
        this.error = '';
        if (!this.title || !this.description || !this.type || !(this.selectedGrade.length > 0) || !(this.topics.length > 0) || !this.selectedLanguage) {
            this.error = 'All form fields except unitNumber and url are required. Please check the form again and fill it out accordingly.';
            this.popToast(this.error, '', 'error');
        }
        else {
            this.loading = true;
            let date = new Date();
            const resource = {
                title: this.title,
                description: this.description,
                type: this.type,
                topic: this.topics,
                grades: this.selectedGrade,
                language: this.selectedLanguage,
                dateCreated: date,
                dateUpdated: date,
                summer: this.isSummerSchool
            }
            if (this.fileInputted) {
                resource['files'] = this.fileData;
            }
            if (this.type == 'Tutorial') {
                resource['tutorialVideo'] = this.tutorialVideo;
            }
            if (this.type === 'Module' || this.type === 'Overview' || this.type === 'Assessment') {
                resource['unitNum'] = this.unitNumber;

                if (this.type == 'Module') {
                    if (this.googleUrl) {
                        resource['google'] = this.googleUrl;
                    }
                    if (this.moduleVideos[0] !== '') {
                        resource['moduleVideos'] = this.moduleVideos;
                    }
                }
            }
            if (this.type === 'Targeted') {
                if (this.demo) {
                    resource['demo'] = this.demo;
                }
                if (this.intro) {
                    resource['intro'] = this.intro;
                }
                resource['dimension'] = this.dimension;
            }

            if (this.type === 'Supplemental') {
                resource.topic.push(this.supplementalTopic);
            }
            console.log('resource: ', resource);

            this.userService.addResource(resource).then(() => {
                this.loading = false;
                this.submit = true;
            }).catch(err => {
                this.error = 'Something went wrong. Check all the fields and try again. Error from server: ' + err.message;
                this.loading = false;
                this.popToast(this.error, '', 'error');
                console.error(err);
            });
        }
    }


    /**
     * Handles hovering over the dropbox
     * @param event 
     */
    toggleHover(event: boolean) {
        this.isHovering = event;
    }


    /**
     * Handles dropping files onto the dropbox
     * @param files
     */
    onDrop(files: FileList) {
        for (let i = 0; i < files.length; i++) {
            this.files.push(files.item(i));
        }
        this.fileInputted = true;
    }


    /**
     * Resets the inputs' values
     */
    resetPage() {
        this.submit = false;
        this.isSubmitting = false;
        this.title = null;
        this.description = null;
        this.type = null;
        this.selectedGrade = null;
        this.topics = [''];
        this.selectedLanguage = null;
        this.unitNumber = null;
        this.url = null;
        this.tutorialVideo = null;
        this.fileInputted = false;
        this.path = null;
        this.googleUrl = null;
        this.submit = false;
        this.isSummerSchool = false;
        this.fileData = [];
        this.files = [];
        this.moduleVideos = [''];
    }


    /**
     * Pops up a toast message using Angular Material's 'SnackBar' Component.
     * @param message The message to display in the toast (snackBar)
     * @param action Optional action button text
     */
    popToast(message: string, action: string, style: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
            panelClass: style
        });
    }


    /**
     * Checks whether or not the form fields are ready to be submitted
     * @returns {boolean}
     */
    fieldsFilled(): boolean {
        let videoFilled = true;
        let moduleFilled = true;
        if (this.type === 'Tutorial') {
            if (!this.tutorialVideo) {
                videoFilled = false;
            }
        }
        if (this.type === 'Module') {
            if (!this.unitNumber) {
                moduleFilled = false;
            }
        }
        return !(videoFilled && moduleFilled && this.title && this.description && this.topics.length > 0 && this.selectedLanguage);
    }


    /**
      * Adds another video link to the moduleVideos array as an empty string.
      * This generates another form field in the template
      */
    addAnotherVideo() {
        this.moduleVideos.push('');
    }


    /**
     * Removes the video from the moduleVideos list
     * @param index The index of the class to remove from the list
     */
    removeVideo(index: number) {
        this.moduleVideos.splice(index, 1);
    }


    /**
      * Adds another topic to the topics array as an empty string.
      * This generates another form field in the template
      */
    addAnotherTopic() {
        this.topics.push('');
    }


    /**
     * Removes the topic from the topics list
     * @param index The index of the class to remove from the list
     */
    removeTopic(index: number) {
        this.topics.splice(index, 1);
    }

    isPrimary() {
		return (this.selectedGrade.includes(0) || this.selectedGrade.includes(1) || this.selectedGrade.includes(2));
	}

    /**
     * Helper function for an *ngFor in the tempate.
     * @param index 
     * @param obj 
     * @returns 
     */
    trackByIndex(index: number, obj: any): any {
        return index;
    }
}