import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

    constructor(private userService: UserService, private snackBar: MatSnackBar) { }

    students: any[] = [];
    classes: string[] = [];
    currentTime: Date;
    loading: boolean;
    schoolAdmin: boolean;
    schoolName: string;
    schoolReportData: any;

    ngOnInit() {
        this.loading = true;
        this.currentTime = new Date();

        this.userService.getUserDetails().then(res => {
            const userData = res.data();
            this.schoolAdmin = userData.schoolAdmin;

            if (this.schoolAdmin) {
                this.schoolName = userData.schoolName;
                this.getAdminReportData();
            } else {
                this.getStudents();
            }
        })

        
    }

   getStudents() {
        this.userService.getStudents().then(res => {
            this.students = res;
            this.userService.getClasses().then(classRes => {
                this.classes = classRes;
                this.loading = false;
            }).catch(err => {
                console.error(err);
                this.loading = false;
                this.popToast(err.message, '');
            });
        }).catch(err => {
            this.loading = false;
            this.popToast(err.message, '');
            console.error(err);
        });
    }

    getAdminReportData() {
        this.userService.getReport(this.schoolName).then(res => {
            this.schoolReportData = res;
            this.loading = false;
        }).catch(err => {
            this.loading = false;
            console.error(err);
            alert('An error has occurred. Please refresh and try again. If it continues, please file a bug report and we will investigate the issue.');
        });
    }



    /**
     * Pops up a toast message using Angular Material's 'SnackBar' Component.
     * @param message The message to display in the toast (snackBar)
     * @param action Optional action button text
     */
    popToast(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 5000,
            panelClass: 'toast-error'
        });
    }


    /**
     * Opens the browser's print dialog.
     */
    print(): void {
        window.print();
    }
}
