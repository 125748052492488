import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import * as _ from 'underscore';
import * as moment from 'moment';
import { filter } from 'minimatch';

@Component({
    selector: 'app-bug-view',
    templateUrl: './bug-view.component.html',
    styleUrls: ['./bug-view.component.scss']
})
export class BugViewComponent implements OnInit {

    bugs: any[];
    selectedBug: any;
    userData: any;
    loading = true;

    constructor(private userService: UserService, private router: Router) { }

    ngOnInit() {
        this.userService.getUserDetails().then(userRes => {
            this.userData = userRes.data();
            if (!this.userData.developer) {
                this.router.navigate(['/ima-dashboard']);
            } else {
                this.userService.getBugs().then(bugRes => {
                    this.bugs = bugRes;
                    this.filterResolvedBugs();
                    this.loading = false;
                }).catch(err => {
                    console.error(err);
                });
            }
        }).catch(err => {
            console.error(err);
        });
    }

    /**
     * Select which bug to display information for in the modal.
     * @param bug The bug object to select
     */
    selectBug(bug: any) {
        this.selectedBug = bug;
    }

    /**
     * Add a true 'resolved' boolean flag to the bug data in Firebase,
     * and then filter that bug from the current list in the DOM.
     */
    resolveBug() {
        this.loading = true;
        this.userService.resolveBug(this.selectedBug.id).then(() => {
            this.selectedBug.data['resolved'] = true;
            this.filterResolvedBugs();
            this.selectedBug = {};
            this.loading = false;
        }).catch(err => {
            console.error(err);
        });
    }

    /**
     * Remove resolved bugs from the list of bugs, and then
     * sort the list by date in descending order.
     */
    filterResolvedBugs() {
        this.bugs = _.reject(this.bugs, (bug) => {
            return bug.data.resolved === true;
        });
        this.bugs = _.sortBy(this.bugs, (bug) => {
            return bug.data.date.toDate().getTime();
        });
        this.bugs.reverse();
    }

    /**
	 * Convert date object to a string.
	 * @param date Date object
	 */
    dateToString(date: any): string {
        return moment(date.seconds * 1000).calendar();
    }
}
