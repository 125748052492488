import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-diagnostic-login',
    templateUrl: './diagnostic-login.component.html',
    styleUrls: ['./diagnostic-login.component.scss']
})
export class DiagnosticLoginComponent implements OnInit {

    code: string;
    loading = false;

    constructor(private router: Router, private userService: UserService, private snackBar: MatSnackBar) { }

    ngOnInit() {
    }

    goToDiagnostic() {
        this.loading = true;
        this.userService.ifCodeExists(this.code).then(res => {
            const diagnostic = res.data().diagnostic;
            this.loading = false;
            this.router.navigate(['/quiz', diagnostic, this.code]);
        }).catch(err => {
            this.loading = false;
            console.error(err);
            this.popToast('Something went wrong. Check your code and try again.', '', 'toast-error');
        });
    }



    /**
     * Pops up a toast message using Angular Material's 'SnackBar' Component.
     * @param message The message to display in the toast (snackBar)
     * @param action Optional action button text
     */
    popToast(message: string, action: string, toastStyle: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
            panelClass: toastStyle
        });
    }

}
