import { Component, OnInit, Input, Output, EventEmitter, SimpleChange, OnChanges } from '@angular/core';


@Component({
  selector: 'app-diagnostic-slide',
  templateUrl: './diagnostic-slide.component.html',
  styleUrls: ['./diagnostic-slide.component.scss']
})
export class DiagnosticSlideComponent implements OnInit, OnChanges {

    @Input() answerFormat: string;
    @Input() answerOptions: any[];
    @Input() trueFalseAnswer;
    @Input() subcategory: string;
    @Input() instruction: string;
    @Input() equation: string;
    @Input() image: string;

    @Output() textInputChanged = new EventEmitter<any>();
    @Output() radioChanged = new EventEmitter<any>();
    @Output() checkboxChanged = new EventEmitter<any>();
    @Output() multipleTextChanged = new EventEmitter<any>();
    @Output() nextSlideEvent = new EventEmitter<any>();

    selectedOption = '';
    checkboxOptions = [];

    multipleText = {
        q1: '',
        q2: '',
        q3: '',
        q4: ''
    };

    constructor() { }

    ngOnInit() {
    }


    /**
     * Need to watch for changes to the checkbox and radio options to know if we need
     * to clear out selections.
     * @param changes The Input values being watched
     */
    ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
        for (const propName in changes) {
            if (propName === 'answerOptions') {
                this.selectedOption = null;
                if (this.answerFormat === 'checkbox') {
                    this.checkboxOptions = [];
                    for (const answerOption of this.answerOptions) {
                        this.checkboxOptions.push({
                            image: answerOption.answerImage,
                            text: answerOption.answerText,
                            wholeNumber: answerOption.wholeNumber,
                            numerator: answerOption.numerator,
                            denominator: answerOption.denominator,
                            correct: answerOption.correct,
                            checked: false
                        });
                    }
                }
            } else if ((propName === 'instruction' || propName === 'image' || propName === 'equation')
                && (this.answerFormat === 'trueFalse' || this.answerFormat === 'textInput')) {
                this.selectedOption = '';
                this.checkboxOptions = [];
            }
        }
    }


    /**
     * Event handler for selecting a radio button. Emits the event to the parent component.
     * @param value The actual text value of the radio option
     * @param isCorrect Boolean representing whether or not the option was the correct choice
     */
    selectRadio(value, isCorrect): void {
        this.selectedOption = value;
        this.radioChanged.emit({ radioValue: value, answerIsCorrect: isCorrect });
    }


    /**
     * Event handler for selecting a checkbox. Emits the event to the parent component for futher handling.
     * @see QuizComponent
     */
    onCheckboxChange(): void {
        this.checkboxChanged.emit(this.checkboxOptions);
    }


    /**
     * Event handler for selecting a checkbox. Emits the event to the parent component for further handling.
     * @see QuizComponent
     */
    onMultipleTextChanged(): void {
        this.multipleTextChanged.emit([this.multipleText.q1, this.multipleText.q2, this.multipleText.q3, this.multipleText.q4]);
    }

}
