import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private userAuthDetails: firebase.User;

    constructor(public router: Router, public afAuth: AngularFireAuth) {
        this.afAuth.authState.subscribe(user => {
            if (user) {
                this.userAuthDetails = user;
              } else {
                this.userAuthDetails = null;
              }
        });
    }

    /**
     * Login to Firebase Authentication.
     * @param email the user's email address
     * @param password the user's password
     */
    login(email: string, password: string): Promise<any> {
        return this.afAuth.auth.signInWithEmailAndPassword(email, password);
    }


    /**
     * Create a new Firebase Authentication account.
     * @param email the new user's email address
     * @param password the new user's desired password
     */
    signup(email: string, password: string): Promise<firebase.auth.UserCredential> {
        return this.afAuth.auth.createUserWithEmailAndPassword(email, password);
    }


    changeEmail(email: string) {
        return this.afAuth.auth.currentUser.updateEmail(email);
    }

    reAuth(password) {
        const user = this.afAuth.auth.currentUser;
        const credential = firebase.auth.EmailAuthProvider.credential(
            user.email,
            password
        );
        return user.reauthenticateWithCredential(credential);
    }


    /**
     * Logout of Firebase Authentication
     */
    logout(): Promise<any> {
        return this.afAuth.auth.signOut();
    }


    /**
     * Check if the user is currently logged in.
     */
    isLoggedIn(): boolean {
        if (this.userAuthDetails == null) {
            return false;
        } else {
            return true;
        }
    }


    /**
     * Use the Google Auth password reset
     * @param email The user's email address
     */
    resetPassword(email: string) {
        return this.afAuth.auth.sendPasswordResetEmail(email);
    }


    /**
     * Return the user's Firebase Authentication ID (uid).
     */
    getUserId(): string {
        return this.userAuthDetails.uid;
    }

    /**
     * Get the current user's email address
     * @returns The currently logged in user's email address
     */
    getUserEmail(): string {
        return this.userAuthDetails.email;
    }
}
