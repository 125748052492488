import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

    showModal = false;

    @Input() showConfirmButton: boolean;
    @Input() title: string;
    @Input() class: string;
    @Input() confirmText: string;
    @Output() modalConfirm = new EventEmitter();

    constructor() { }

    ngOnInit() {
    }


    /**
     * Show the diagnostic confirmation modal
     */
    openModal() {
        this.showModal = true;
    }



    /**
     * Hide the diagnostic confirmation modal
     * @param event Click Event
     */
    closeModal(event: Event) {
        event.stopImmediatePropagation();
        this.showModal = false;
    }



    /**
     * This is just used for the modal
     * @param event Click Event
     */
    stopProp(event: Event) {
        event.stopImmediatePropagation();
    }



    /**
     * Click handler for the modal confirm button. Fires event emitter
     * to be caught in parent component.
     */
    confirm() {
        this.modalConfirm.emit();
    }

}
