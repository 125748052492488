import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserService } from '../services/user.service';

@Component({
    selector: 'app-active-codes',
    templateUrl: './active-codes.component.html',
    styleUrls: ['./active-codes.component.scss']
})
export class ActiveCodesComponent implements OnInit {

    @Input() students: any[];
    @Output() removeCode = new EventEmitter();

    constructor(private userService: UserService) { }

    ngOnInit() {
    }

    remove(student: any, code: any) {
        this.removeCode.emit({student, code});
    }

    getDiagnosticType(shortName: string) {
        return this.userService.getDiagnosticName(shortName);
    }
}
