import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import * as _ from 'underscore';

@Component({
    selector: 'app-resource-targeted',
    templateUrl: './resource-targeted.component.html',
    styleUrls: ['./resource-targeted.component.scss']
})
export class ResourceTargetedComponent implements OnInit {

    _grade: number;

    @Input() activities: any[];
    @Input() admin: boolean;

    @Input() set grade(value: number) {
		this._grade = value;
        this.selectedActivity = null;
        this.clearSortedActivities();
        this.filterActivities();
	}
	get grade(): number {
		return this._grade;
	}



    @Output() editActivity = new EventEmitter();

    sortedActivities = {};
    activeDimensions: string[];

    modalVideoUrl: SafeResourceUrl;
    selectedActivity: any;

    dimensions = ['Spatial Reasoning', 'Number Facts', 'Number Sequencing', 'Relational Thinking', 'Interpreting Context', 'Measurement', 'Algebraic Reasoning', 'Multiplication & Division Fluency', 'Fraction Concepts', 'Ratio & Proportion Concepts', 'Place Value & Decimal Concepts', 'Geometric Measurement'];

    constructor(private sanitizer: DomSanitizer) { }

    ngOnInit() {
        this.clearSortedActivities();
        this.filterActivities();
    }


    /**
     * Sort activites by dimension and then alphabetically by title
     */
    filterActivities() {
        for (let activity of this.activities) {
            this.sortedActivities[activity.dimension].push(activity);
        }
        const keys = _.keys(this.sortedActivities);
        for (let key of keys) {
            this.sortedActivities[key] = _.sortBy(this.sortedActivities[key], 'title');
        }
    }


    clearSortedActivities() {
        this.sortedActivities = {
            'Spatial Reasoning': [],
            'Number Facts': [],
            'Number Sequencing': [],
            'Relational Thinking': [],
            'Interpreting Context': [],
            'Measurement': [],
            'Algebraic Reasoning': [],
            'Multiplication & Division Fluency': [],
            'Fraction Concepts': [],
            'Ratio & Proportion Concepts': [],
            'Place Value & Decimal Concepts': [],
            'Geometric Measurement': []
        };
    }

    /**
     * Sets the selected activity
     * @param activity The activity to select
     */
    selectActivity(activity: any) {
        this.selectedActivity = activity;
    }


    /**
	 * Parses a filename out of a full Firebase storage path
	 * @param path The Firebase Storage path
	 * @returns {string} The filename
	 */
    getFilename(path: string): string {
		return path.substring(29);
	}


    /**
	 * Creates a vimeo embed code from a Vimeo URL string
	 * @param {string} video The original Vimeo URL
	 */
    getVimeoEmbed(video: string) {
        let videoID = new URL(video).pathname;
        this.modalVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://player.vimeo.com/video/' + videoID.slice(1) + '?byline=0&portrait=0');
    }

    /**
	 * Tells the parent component that the resource needs to be edited.
	 * Parent component should navigate to /edit-resource/{id}
	 * @param activity The resource to be edited
	 */
    edit(activity: any) {
        this.editActivity.emit(activity);
    }

    /**
     * Used in the template to provide an index in an *ngFor loop.
     * @param index 
     * @param obj 
     */
     trackByIndex(index: number, obj: any): any {
        return index;
    }

}
