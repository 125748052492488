import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {  MatTableExporterDirective, ExportType } from 'mat-table-exporter';
import { UserService } from '../../services/user.service';
import { DiagnosticService } from '../../services/diagnostic.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as _ from 'lodash';

// Interface used for Angular Material Table
// See: https://material.angular.io/components/table/overview
export interface Student {
    id: string;
    lastName: string;
    firstName: string;
    class: string;
    grade: number;
    numResults: number;
    'ima-ar': number;
    'ima-dc': number;
    'ima-fc': number;
    'ima-gm': number;
    'ima-mdf': number;
    'ima-pvc': number;
    'ima-rpc': number;
}

@Component({
    selector: 'app-reports-list',
    templateUrl: './reports-list.component.html',
    styleUrls: ['./reports-list.component.scss']
})
export class ReportsListComponent implements OnInit {

    loading: boolean;

    @Input() students: any[];
    @Input() classes: string[];
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatTableExporterDirective, {static: true}) matTableExporter: MatTableExporterDirective;

    studentLatestResults: any[] = [];
    diagnostics: any[];

    filterText: string;

    displayedColumns: string[] = [
        'report',
        'lastName', 
        'firstName',
        'class',
        'grade',
        'ima-ar',
        'ima-dc',
        'ima-fc',
        'ima-gm',
        'ima-mdf',
        'ima-pvc',
        'ima-rpc'
    ];

    dataSource: MatTableDataSource<any>;

    constructor(
        private userService: UserService,
        private diagService: DiagnosticService,
        private snackBar: MatSnackBar
    ) { }


    ngOnInit() {
        this.loading = true;
        
        this.diagnostics = this.diagService.getDiagnostics();
        this.loadLatestDiagnostics();
        
        this.loading = false;
    }



    /**
     * Loop through students and create an object for each one that contains their
     * basic info and their results for their latest diagnostic in each category.
     */
    loadLatestDiagnostics(): void {
        for (let i=0; i<this.students.length; i++) {
            const student = this.students[i];

            let studentReportObj = {
                id: student.firebaseID,
                grade: student.data.grade,
                lastName: student.data.lastName,
                firstName: student.data.firstName,
                class: student.data.class,
                numResults: student.data.results.length
            };

            for (let j=0; j<this.diagnostics.length; j++) {
                const category = this.diagnostics[j];

                this.userService.queryResultsByStudentAndCategory(student.firebaseID, category.name).then(res => {
                    if (res && res.length > 0) {
                        res = _.orderBy(res, (e) => { return e.data.date.seconds}, ['desc']);
                        studentReportObj[category.queryParam] = res[0];
                    }
                    if (i === this.students.length -1 && j === this.diagnostics.length - 1) {
                        this.buildReportTable();
                    }
                }).catch(err => {
                    this.loading = false;
                    console.error(err);
                });
            }
            this.studentLatestResults.push(studentReportObj);
        }
    }



    /**
     * Uses student data to format an object that is fed to the reports table.
     */
    buildReportTable(): void {
        let tempStudentArray: Student[] = [];

        for (let i=0; i<this.studentLatestResults.length; i++) {
            let student = this.studentLatestResults[i];
            
            let finalStudentObj: Student = {
                id: student.id,
                lastName: student.lastName,
                firstName: student.firstName,
                class: student.class,
                grade: student.grade,
                numResults: student.numResults,
                'ima-ar': (student['ima-ar'] ? student['ima-ar'].data.percentCorrect : 'N/A'),
                'ima-dc': (student['ima-dc'] ? student['ima-dc'].data.percentCorrect : 'N/A'),
                'ima-fc': (student['ima-fc'] ? student['ima-fc'].data.percentCorrect : 'N/A'),
                'ima-gm': (student['ima-gm'] ? student['ima-gm'].data.percentCorrect : 'N/A'),
                'ima-mdf': (student['ima-mdf'] ? student['ima-mdf'].data.percentCorrect : 'N/A'),
                'ima-pvc': (student['ima-pvc'] ? student['ima-pvc'].data.percentCorrect : 'N/A'),
                'ima-rpc': (student['ima-rpc'] ? student['ima-rpc'].data.percentCorrect : 'N/A'),
            }
            tempStudentArray.push(finalStudentObj);
        }
        this.dataSource = new MatTableDataSource(tempStudentArray);
        this.dataSource.sort = this.sort;
    }



    filterChange() {
        this.dataSource.filter = this.filterText;
    }


    /**
     * Exports the angular material mat-table as a spreadsheet
     * @param type The requested file type. ('excel' or 'csv')
     */
    export(type: string) {
        const date = new Date().toDateString();
        if (type === 'excel') {
            this.matTableExporter.exportTable(ExportType.XLSX, {fileName: 'DMTI IMA Report ' + date});
        } else {
            this.matTableExporter.exportTable(ExportType.CSV, {fileName: 'DMTI IMA Report ' + date});
        }   
    }


    /**
     * Used to style the table. Called in the template for each table cell's [ngClass] input.
     * @returns string - The class to be added to the table cell
     * @param score Expects a number, but if no score is present it will be the string 'N/A'
     */
    checkScore(score: any): string {
        if (score !== null && score !== undefined) {
            if (typeof(score) === 'number') {
                if (score > 67.0) {
                    return 'mat-cell great';
                }else if (score > 33.0) {
                    return 'mat-cell okay';
                }else {
                    return 'mat-cell bad';
                }
            } else {
                return 'mat-cell empty';
            }
        } else {
            return 'mat-cell';
        }
    }



    /**
     * Used in the template to determine if a percent sign should be added to the table cell.
     * @param valueToCheck Expected to be either the percent correct as a number, or the string 'N/A'
     */
    ifNumberReturnPercent(valueToCheck: number | string): string {
        return (typeof(valueToCheck) === 'number' ? '%' : '');
    }



    /**
     * Pops up a toast message using Angular Material's 'SnackBar' Component.
     * @param message The message to display in the toast (snackBar)
     * @param action Optional action button text
     */
    popToast(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 5000,
            panelClass: 'toast-error'
        });
    }

}
