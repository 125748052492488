import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { FilenameSortPipe } from '../pipes/filename-sort.pipe';
import * as _ from 'underscore';

@Component({
	selector: 'app-resource',
	templateUrl: './resource.component.html',
	styleUrls: ['./resource.component.scss']
})
export class ResourceComponent implements OnInit {

	loading = true;

	private _grade: number;
	private _language: string;

	@Input() resources: any[];
	@Input() spanishResources: any[];
	@Input() units: string[];

	@Input() summer: boolean;

    @Input() admin: boolean;

	@Input() set grade(value: number) {
		this.loading = true;
		this._grade = value;
		this.selectUnit(0);
        this.setSupplementals();
        this.setTargetedActivities();
		this.setVideoTutorials();
		this.calendar = _.where(this.resources, {type: 'Calendar'})[0];
		this.loading = false;
	}

	@Input() set language(value: string) {
		this._language = value;
		if (value === 'English') {
			this.assessment = _.where(this.resources, {type: 'Assessment', unitNum: this.selectedUnit + 1})[0];
			this.module = _.where(this.resources, {type: 'Module', unitNum: this.selectedUnit + 1})[0];
		} else if (value === 'Spanish') {
			this.spanishAssessment = _.where(this.spanishResources, {type: 'Assessment', unitNum: this.selectedUnit + 1})[0];
			this.spanishModule = _.where(this.spanishResources, {type: 'Module', unitNum: this.selectedUnit + 1})[0];
		}	
	}

	get grade(): number { return this._grade; }
	get language(): string { return this._language; }

	calendar: any;
	overview: any;
	assessment: any;
	spanishAssessment: any;
	module: any;
	spanishModule: any;

    supplementals: any[];
    targetedActivities: any[];
    targetedActivitiesByGrade: any[];
	supplementalsByGrade: any[];
	videoTutorials: any[];

	selectedUnit = 0;

    modalVideoUrl: SafeResourceUrl;

	constructor(private router: Router, private sanitizer: DomSanitizer) { }

	ngOnInit() {
		this.calendar = _.where(this.resources, {type: 'Calendar'})[0];
		this.overview = _.where(this.resources, {type: 'Overview', unitNum: 1})[0];
		this.assessment = _.where(this.resources, {type: 'Assessment', unitNum: 1})[0];
        this.module = _.where(this.resources, {type: 'Module', unitNum: 1})[0];
        this.setTargetedActivities();
		this.setSupplementals();
		this.setVideoTutorials();
		this.loading = false;
	}


	/**
	 * Filters modules, overviews, and assessments by unit number
	 * @param {number} unit The unit number
	 */
	selectUnit(unit: number) {
		this.selectedUnit = unit;
		this.overview = _.where(this.resources, {type: 'Overview', unitNum: unit + 1})[0];
		
		this.module = _.where(this.resources, {type: 'Module', unitNum: unit + 1})[0];
		this.assessment = _.where(this.resources, {type: 'Assessment', unitNum: unit + 1})[0];

		if (this.spanishResources && this.spanishResources.length > 0) {
			this.spanishAssessment = _.where(this.spanishResources, {type: 'Assessment', unitNum: unit + 1})[0];
			this.spanishModule = _.where(this.spanishResources, {type: 'Module', unitNum: unit + 1})[0];
		}
		
	}


	/**
	 * Filters the Supplemental Materials by grade
	 * @param {number} grade The grade to filter on
	 */
	setSupplementals() {
		this.supplementals = _.where(this.resources, {type: 'Supplemental'});
		this.supplementals = _.sortBy(this.supplementals, 'title');
    }


	/**
	 * Filters the Targeted activities by grade
	 * @param grade The grade to filter on
	 */
    setTargetedActivities() {
        this.targetedActivities = _.where(this.resources, {type: 'Targeted'});
		this.targetedActivities = _.sortBy(this.targetedActivities, 'title');
    }


	/**
	 * Filters video tutorials by grade
	 * @param grade The grade to filter on
	 */
	setVideoTutorials() {
		this.videoTutorials = _.where(this.resources, {type: 'Tutorial'});
	}


	/**
	 * Creates a vimeo embed code from a Vimeo URL string
	 * @param {string} video The original Vimeo URL
	 */
	getVimeoEmbed(video: string) {
        let videoID = new URL(video).pathname;
        this.modalVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://player.vimeo.com/video/' + videoID.slice(1) + '?byline=0&portrait=0');
    }


	/**
	 * Parses a filename out of a full Firebase storage path
	 * @param path The Firebase Storage path
	 * @returns {string} The filename
	 */
	getFilename(path: string) {
		return path.substring(29);
	}


	/**
	 * Navigates to the edit resource page
	 * @param resource The resource to be edited
	 */
	editResource(resource: any) {
		this.router.navigate(['/edit-resource', resource.id], {
			state: {
				resource: resource,
				firebaseID: resource.id
			}
		});
    }

}