import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import * as _ from 'underscore';

@Component({
	selector: 'app-supplemental-page',
	templateUrl: './supplemental-page.component.html',
	styleUrls: ['./supplemental-page.component.scss']
})
export class SupplementalPageComponent implements OnInit {

	supplementals: any[];
	filteredSupplementals: any[];
	summerSupplementals: any[];
	filteredSummerSupps: any[];
	admin = false;
	summer = false;
	grades = [0,1,2,3,4,5,6,7,8,9];
	selectedGrades = [0,1,2,3,4,5,6,7,8,9];
	filterText: string = '';
	topics = ['DMTI Overviews', 'Fluency & Flexibility', 'Measurement', 'Data', 'Geometry', 'Number Primary', 'Number Intermediate', 'Number Middle School', 'Algebraic Reasoning'];
	selectedTopics = ['DMTI Overviews', 'Fluency & Flexibility', 'Measurement', 'Data', 'Geometry', 'Number Primary', 'Number Intermediate', 'Number Middle School', 'Algebraic Reasoning'];
	
	constructor(private userService: UserService, private router: Router) {
	}

	ngOnInit() {
		
		this.userService.getUserDetails().then(res => {
			let data = res.data();
			if (data.admin) {
				this.admin = data.admin;
			}
			this.userService.getResourcesByType('Supplemental').then(res => {
				this.supplementals = res;
				this.separateSummerResources();
			}).catch(err => {
				console.error(err);
				alert('An error occurred. Please refresh and try again. If this continues, please submit a bug report.');
			});

		}).catch(err => {
			console.error(err);
			alert('An error occurred. Please refresh and try again. If this continues, please submit a bug report.');
		})
	}

	/**
	 * Toggle summer resources
	 * @param checked Whether or not the toggle is checked
	 */
	 toggleSummer(checked: boolean) {
		this.summer = checked;
	}

	/**
	 * Navigate to the edit resource page
	 * @param resource The resource to be edited
	 */
	editResource(resource: any) {
		this.router.navigate(['/edit-resource', resource.id], {
			state: {
				resource: resource,
				firebaseID: resource.id
			}
		});
    }

	/**
	 * Separate school year and summer supplementals in separate arrays,
	 * then initialize filtered arrays.
	 */
	 separateSummerResources() {
		this.summerSupplementals = _.where(this.supplementals, {summer: true});
		this.filteredSummerSupps = _.map(this.summerSupplementals, (obj) => {return obj});

		this.supplementals = _.reject(this.supplementals, (resource) => {
			return resource.summer === true;
		});
		this.filteredSupplementals = _.map(this.supplementals, (obj) => {return obj});
	}

	/**
	 * Changes the displayed Supplemental Materials based on the selected grades and topics
	 */
	changeFilter() {
		if (this.summer) {
			this.filteredSummerSupps = _.filter(this.summerSupplementals, (supp) => {
				return (_.some(this.selectedGrades, function(grade) {
					return _.contains(supp.grades, grade);
				})) && (_.some(this.selectedTopics, function(topic) {
					return _.contains(supp.topic, topic);
				}))
			})
		} else {
			this.filteredSupplementals = _.filter(this.supplementals, (supp) => {
				return (_.some(this.selectedGrades, function(grade) {
					return _.contains(supp.grades, grade);
				})) && (_.some(this.selectedTopics, function(topic) {
					return _.contains(supp.topic, topic);
				}))
			});
		}
		
	}


	/**
	 * Changes the displayed Supplemental Materials by searching for the filterText
	 * in each Supplemental Material's title and topic array
	 */
	nameSearch() {
		this.changeFilter();
		this.filteredSupplementals = _.filter(this.filteredSupplementals, (supp) => {
			let title = supp.title as string;
			let topics = supp.topic as string[];
			topics = _.map(topics, (topic) => {
				return topic.toLowerCase();
			}) as string[];
			title = title.toLowerCase();
			let lowercaseFilterText = this.filterText.toLowerCase();
			return title.includes(lowercaseFilterText) || topics.indexOf(lowercaseFilterText) > -1;
		});
	}

}
