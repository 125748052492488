import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'underscore';
import { UserService } from '../../services/user.service';

@Component({
	selector: 'app-summer-resource-page',
	templateUrl: './summer-resource-page.component.html',
	styleUrls: ['./summer-resource-page.component.scss']
})
export class SummerResourcePageComponent implements OnInit {

	admin: boolean = false;

	allResources: any[] = [];
	selectedResources: any[];
	filteredResources: any[][] = [[], [], [], [], [], [], []];

	selectedGrade = 0;
	curricMap: any;


	units = [
		[
			'Number Concepts - Composing, Decomposing, PV', 'Number Concepts - Composing, Decomposing, PV',
			'Operations and Algebraic Thinking - Addition & Subtraction Problem Types & Strategies', 'Operations and Algebraic Thinking - Addition & Subtraction Problem Types & Strategies',
			'Measurement - Linear Measurement & Connections to Place Value, Measurable Attributes, Misconceptions', 'Measurement - Linear Measurement & Connections to Place Value, Measurable Attributes, Misconceptions',
			'Geometry & Spatial Reasoning - Shape Classification, Geometric Measurement, Data Analysis Connections', 'Geometry & Spatial Reasoning - Shape Classification, Geometric Measurement, Data Analysis Connections'
		],
		[
			'Number Concepts - Composing, Decomposing, PV', 'Number Concepts - Composing, Decomposing, PV',
			'Operations and Algebraic Thinking - Addition & Subtraction Problem Types & Strategies', 'Operations and Algebraic Thinking - Addition & Subtraction Problem Types & Strategies',
			'Measurement - Linear Measurement & Connections to Place Value, Measurable Attributes, Misconceptions', 'Measurement - Linear Measurement & Connections to Place Value, Measurable Attributes, Misconceptions',
			'Geometry & Spatial Reasoning - Shape Classification, Geometric Measurement, Data Analysis Connections', 'Geometry & Spatial Reasoning - Shape Classification, Geometric Measurement, Data Analysis Connections'
		],
		[
			'Number Concepts - Composing, Decomposing, PV', 'Number Concepts - Composing, Decomposing, PV',
			'Operations and Algebraic Thinking - Addition & Subtraction Problem Types & Strategies', 'Operations and Algebraic Thinking - Addition & Subtraction Problem Types & Strategies',
			'Measurement - Linear Measurement & Connections to Place Value, Measurable Attributes, Misconceptions', 'Measurement - Linear Measurement & Connections to Place Value, Measurable Attributes, Misconceptions',
			'Geometry & Spatial Reasoning - Shape Classification, Geometric Measurement, Data Analysis Connections', 'Geometry & Spatial Reasoning - Shape Classification, Geometric Measurement, Data Analysis Connections'
		],
		[
			'Number Concepts - PV Operations & Algebraic Thinking - Using Place Value Strategies Problem Types & Models for Add/Sub', 'Number Concepts - PV Operations & Algebraic Thinking - Using Place Value Strategies Problem Types & Models for Add/Sub',
			'Operations & Algebraic Thinking - Multiplication & Divison', 'Operations & Algebraic Thinking - Multiplication & Divison',
			'Number Concepts & Operations - Fractions (With Some Decimal Work in Grade 5)', 'Number Concepts & Operations - Fractions (With Some Decimal Work in Grade 5)',
			'Measurement & Geometry - Shape Classification, Geometric Measurement, Data Analysis Connections, Area and Length (With Volume for Grade 5)', 'Measurement & Geometry - Shape Classification, Geometric Measurement, Data Analysis Connections, Area and Length (With Volume for Grade 5)'
		],
		[
			'Number Concepts - PV Operations & Algebraic Thinking - Using Place Value Strategies Problem Types & Models for Add/Sub', 'Number Concepts - PV Operations & Algebraic Thinking - Using Place Value Strategies Problem Types & Models for Add/Sub',
			'Operations & Algebraic Thinking - Multiplication & Divison', 'Operations & Algebraic Thinking - Multiplication & Divison',
			'Number Concepts & Operations - Fractions (With Some Decimal Work in Grade 5)', 'Number Concepts & Operations - Fractions (With Some Decimal Work in Grade 5)',
			'Measurement & Geometry - Shape Classification, Geometric Measurement, Data Analysis Connections, Area and Length (With Volume for Grade 5)', 'Measurement & Geometry - Shape Classification, Geometric Measurement, Data Analysis Connections, Area and Length (With Volume for Grade 5)'
		],
		[
			'Number Concepts - PV Operations & Algebraic Thinking - Using Place Value Strategies Problem Types & Models for Add/Sub', 'Number Concepts - PV Operations & Algebraic Thinking - Using Place Value Strategies Problem Types & Models for Add/Sub',
			'Operations & Algebraic Thinking - Multiplication & Divison', 'Operations & Algebraic Thinking - Multiplication & Divison',
			'Number Concepts & Operations - Fractions (With Some Decimal Work in Grade 5)', 'Number Concepts & Operations - Fractions (With Some Decimal Work in Grade 5)',
			'Measurement & Geometry - Shape Classification, Geometric Measurement, Data Analysis Connections, Area and Length (With Volume for Grade 5)', 'Measurement & Geometry - Shape Classification, Geometric Measurement, Data Analysis Connections, Area and Length (With Volume for Grade 5)'
		],
		[
			'Ratio and Unit Rate', 'Ratio and Percent', 'Expressions & Order of Operations', 'Properties and Equations', 'Surface Area', 'Volume'
		]
	];

	loading: boolean = false;
	agreed: boolean = true;

	constructor(private userService: UserService, private router: Router) { }

	ngOnInit() {
		this.loading = true;

		// Check for admin status and terms agreement
		this.userService.getUserDetails().then(res => {
			let data = res.data();
			if (data.admin) {
				this.admin = data.admin;
			}
			if (data.agreed !== null && data.agreed !== undefined) {
				this.agreed = data.agreed;
			} else {
				this.agreed = false;
			}
		});

		// Grab resources
		this.userService.getSummerResources().then(resourceResponse => {
			for (let resource of resourceResponse) {
				let data = resource.data;
				data['id'] = resource.firebaseID;
				this.allResources.push(data);
			}
			this.prepareData();
			this.loading = false;
		}).catch(err => {
			this.loading = false;
			console.error(err);
			alert('An error occurred.');
		});

	}


	/**
	 * Establish our dataset by separating and sorting
	 */
	prepareData() {
		this.curricMap = _.findWhere(this.allResources, {type: 'Map'});
		this.sortByGrade(this.allResources, this.filteredResources);
		this.selectedResources = [...this.filteredResources[this.selectedGrade]];
	}


	/**
	 * Organizes an array of resources into a 2D array where each initial index corresponds to
	 * the grade 0-9. Within that index is an array of all resources for that grade.
	 * @param source Source Array
	 * @param destination Destination Array for sorted Resources
	 */
	sortByGrade(source: any[], destination: any[]) {
		for (let i = 0; i < 7; i++) {
			destination[i] = _.filter(source, (resource) => {
				return resource.grades.indexOf(i) !== -1;
			});
		}
	}


	/**
	 * The event handler for changing the grade tab
	 * @param {number} grade The grade selected
	 */
	onTabChange(grade: number) {
    this.loading = true;
    this.selectedResources = [...this.filteredResources[grade]];
		this.selectedGrade = grade;
    this.loading = false;
	}



	/**
	 * Agree to the terms and conditions
	 */
	agree() {
		this.loading = true;
		this.userService.agreeToTerms().then(() => {
			this.agreed = true;
			this.loading = false;
		}).catch(err => {
			this.loading = false;
			console.error(err);
			alert('Something went wrong. Please refresh and try again.');
		});
	}

	editResource(resource: any) {
		this.router.navigate(['/edit-resource', resource.id], {
			state: {
				resource: resource,
				firebaseID: resource.id
			}
		});
    }

}