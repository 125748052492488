import { Component, OnInit } from '@angular/core';
import { DiagnosticService } from '../../services/diagnostic.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-diagnostic-list-page',
    templateUrl: './diagnostic-list-page.component.html',
    styleUrls: ['./diagnostic-list-page.component.scss']
})
export class DiagnosticListPageComponent implements OnInit {

    diagnostics: any[];

    constructor(private diagService: DiagnosticService, private router: Router) { }

    ngOnInit() {
        this.diagnostics = this.diagService.getQuizList();
    }

    onSelectDiagnostic(diagnostic: any) {
        this.router.navigate(['diagnostics', diagnostic.detailFile]);
    }

}
