import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-results-details',
  templateUrl: './results-details.component.html',
  styleUrls: ['./results-details.component.scss']
})
export class ResultsDetailsComponent implements OnInit {

    @Input() results: any;
    @Input() student: any;

    constructor() { }

    ngOnInit() {
    }

    /**
     * Grab the current date and time aand return as a string
     */
    getDateString(): string {
        return new Date(this.results.date.seconds * 1000).toString();
    }

    /**
     * Returns a percentage as a float rounded to two decimal places.
     * @param correct Number of correct answers
     * @param total Total number of questions
     */
    getPercentage(correct: number, total: number) {
        if (correct === 0) {
            return 0;
        }
        let percentCorrect = (correct / total) * 100;
        return Number.parseFloat(percentCorrect.toFixed(2));
    }

    /**
     * Print button handler
     */
    print(): void {
        window.print();
    }

}
